<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="19.108"
        height="19.108"
        viewBox="0 0 19.108 19.108"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="13.512"
                    height="13.511"
                    fill="#ed3232"
                />
            </clipPath>
        </defs>
        <g
            id="Group_27"
            data-name="Group 27"
            transform="translate(2.798 1.241)"
        >
            <g
                id="Group_26"
                data-name="Group 26"
                transform="translate(6.756 17.868) rotate(-135)"
            >
                <g
                    id="Group_15"
                    data-name="Group 15"
                    clip-path="url(#clip-path)"
                >
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M3.992,13.509a10.019,10.019,0,0,0,1.874-.228A10.09,10.09,0,0,0,8.6,12.234a9.694,9.694,0,0,0,2.348-1.868A9.168,9.168,0,0,0,11.869,9.2a9.645,9.645,0,0,0,1.408-3.3,9.728,9.728,0,0,0,.236-2.1,3.7,3.7,0,0,0-.487-1.857A3.8,3.8,0,0,0,10.84.168,3.5,3.5,0,0,0,9.777,0a.419.419,0,0,0-.439.3Q8.744,1.783,8.15,3.269c-.155.389-.308.779-.463,1.169a.455.455,0,0,0-.04.276A.39.39,0,0,0,7.9,5l.77.309.977.392c.1.042.105.042.1.153a3.868,3.868,0,0,1-.29,1.218,4.212,4.212,0,0,1-.608,1.051A4.272,4.272,0,0,1,6.212,9.692c-.136.024-.275.032-.412.048A.061.061,0,0,1,5.726,9.7Q5.416,8.913,5.1,8.131c-.033-.083-.064-.168-.1-.25a.383.383,0,0,0-.517-.217c-.323.125-.644.258-.965.387l-2.212.886c-.332.133-.666.262-1,.394A.428.428,0,0,0,0,9.8a3.781,3.781,0,0,0,3.111,3.647,4.07,4.07,0,0,0,.879.065"
                        transform="translate(-0.001 0)"
                        fill="#ed3232"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<template>
    <div :class="[active ? 'z-30' : '', {'!border-0': selectedNotVisible}]" class="formulate-select max-w-[75vw]">
        <div
            v-if="disabled"
            :class="[{ '!bg-zinc-500/25': disabled }]"
            class="absolute top-0 left-0 w-full h-full rounded-[10px] z-50 border border-[#DCDCDC]"
        ></div>
        <button
            :disabled="disabled"
            class="flex justify-between items-center cursor-pointer w-full"
            type="button"
            @click="clickInSide"
            @click.stop="toggle"
        >
            <div
                :class="[
            label && hasValue ? 'text-blue-500' : 'text-gray-500',
            { 'text-gray-500': disabled }, {'px-[16px] !text-white': selectedNotVisible}
            ]"
                class="font-light text-sm flex items-center h-[45px] text-left"
            >
                <span>{{ selectedNotVisible ? placeholder : (label || placeholder) }}</span>
            </div>

            <div class="flex items-center gap-1">
                <button
                    v-if="allowReset && values"
                    :disabled="disabled"
                    class="p-0.5"
                    @click="reset"
                >
                    <custom-icon
                        :h="12"
                        :w="12"
                        class=" transform rotate-45 -mt-2 ml-1"
                        icon="plus"
                    />
                </button>

                <div
                    :class="{'text-white': selectedNotVisible,
                        'transform transition text-green-400': true,
                        'rotate-180': !active,
                    }"
                >
                    <custom-icon :h="12" :w="12" icon="chevron"/>
                </div>
            </div>
        </button>
        <div v-click-outside="clickOutside" @click="() => active">
            <FormKit
                v-model="values"
                :classes="{outer: 'input-select'}"
                :disabled="disabled"
                :name="name"
                :options="options"
                :outer-class="{ 'is-hidden': !active, 'z-20': active }"
                :type="multiselect ? 'checkbox' : 'radio'"
                :validation="validation"
                :validation-messages="validationMessages"
                :validation-label="validationName"
                :validation-rules="validationRules"
                error-behavior="submit"
                ref="formkitRef"
            />
        </div>
    </div>
</template>

<script>
import vClickOutside from "click-outside-vue3"
import CustomIcon from "@/elements/v2/CustomIcon";
import {mapGetters} from "vuex";

export default {
    name: "CustomSelect",
    components: {CustomIcon},
    props: {
        options: {
            type: Array,
            require: true,
        },
        name: [String, Number],
        defaultValue: [String, Number, Array],
        multiselect: Boolean,
        placeholder: String,
        validation: String,
        validationName: String,
        validationRules: [Object, String],
        validationMessages: Object,
        disabled: Boolean,
        additionalField: Boolean,
        metafieldId: Number,
        allowReset: {
            type: Boolean,
            default: false,
        },
        hide: Boolean,
        selectedNotVisible: Boolean,
        classes: String,
        callFields: Boolean
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data: () => ({
        values: [],
        active: false,
        initialRender: false,
    }),

    computed: {
        ...mapGetters('dicts', ['select']),
        selection() {
            return !this.metafieldId ? this.select === this.name : this.select === `${this.name}-${this.metafieldId}`;
        },
        label() {
            let finalValue = this.multiselect
                ? this.values[0]
                : this.values;
            if (this.hasValue) {
                finalValue = this.options.find(
                    (option) => option.id === finalValue
                )?.label;
                if (this.multiselect) {
                    if (this.values.length > 1) {
                        finalValue = `${finalValue} + ${
                            this.values.length - 1
                        }`;

                    }
                    return finalValue;
                } else {
                    return finalValue;
                }
            }
            return this.placeholder || "-";
        },
        hasValue() {
            return this.multiselect ? this.values.length : this.values;
        },
    },
    updated() {
        if (!this.values) {
            this.values = this.defaultValue;
        }
    },
    created() {
        if (
            this.multiselect &&
            !this.values?.length &&
            (this.defaultValue?.length || this.defaultValue === 0)
        ) {
            this.values = this.defaultValue;
        }
        if (
            !this.multiselect &&
            this.values?.length === 0 &&
            this.defaultValue !== 0
        ) {
            this.values = this.defaultValue;
        }

        // this.initialRender = false;
    },
    beforeUnmount() {
        this.values = null;
        //this.$refs.formkitRef = null;
        //delete this.$refs.formkitRef; // TODO: check if it fails elsewhere
    },
    methods: {
        toggle() {
            this.active = !this.active;
        },
        clickOutside() {
            this.active = false;
        },
        clickInSide() {
            const selectId = !this.metafieldId ? this.name : `${this.name}-${this.metafieldId}`;
            this.$store.dispatch('dicts/updateSelect', selectId)
        },
        reset(e) {
            e.stopPropagation();
            this.values = null;
            this.$emit("update-form", {key: this.name, value: null});
        },

    },
    watch: {
        selection: {
            handler(val) {
                if (!val) {
                    this.active = false;
                }
            },
            deep: false
        },
        defaultValue: {
            handler(val) {
                if (!this.active) {
                    this.values = val
                }
            },
            deep: true
        },
        values(values) {
            if (this.multiselect) {
                if (!this.values.length) {
                    if (this.options[0]?.id === -1) {
                        this.$emit("update-form", {
                            key: this.name,
                            value: -1,
                            id: -1
                        });
                    }
                    return;
                }
                this.active = false;
                if (values.length > 1) {
                    if (values.some(el => el === -1)) {
                        this.values = values.filter(
                            (value) => value != -1
                        );
                    }

                    if (values[values.length - 1] === -1) {
                        this.values = values.filter(
                            (value) => value == -1
                        );
                    }
                }

                this.$emit("update-form", {
                    key: this.name,
                    value: this.multiselect ? this.values : this.values,
                });

            } else {
                if (!this.values) return;
                if (this.additionalField) {
                    if (this.callFields && this.active) {
                        this.$emit("update-form-field", {
                            metafield_id: +this.metafieldId,
                            metafield_selectable_value_id: +values
                        });
                    } else {
                        this.$emit("update-form", {
                            metafield_id: +this.metafieldId,
                            metafield_selectable_value_id: +values
                        });
                    }
                } else {
                    this.$emit("update-form", {
                        key: this.name,
                        value: values,
                    });
                }
                this.active = false;
            }
        }
    },
};
</script>

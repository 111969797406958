<template>
    <router-link :type="typeNative" :to="to" :class="classObject" v-if="to">
        <slot></slot>
    </router-link>
    <button :type="typeNative" :class="classObject" v-else>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "CustomButton",
    props: {
        type: {
            type: String,
            require: false,
            default: "empty",
        },
        typeNative: {
            type: String,
            require: false,
            default: "button",
        },
        to: {
            type: String,
            require: false,
        },
    },

    computed: {
        classObject() {
            let className =
                "button";
            switch (this.type) {
                case "empty":
                    className = `${className} text-blue-400 border border-blue-400`;
                    break;
                case "main-green":
                    className = `${className} button--main-green`;
                    break;
                case "main-dark":
                    className = `${className} button--main-dark`;
                    break;
                case "second-gray":
                    className = `${className} button--second-gray`;
                    break;
                case "second-green":
                    className = `${className} button--second-green`;
                    break;
                case "second-blue":
                    className = `${className} button--second-blue`;
                    break;
                case "second-red":
                    className = `${className} button--second-red`;
                    break;
                case "link":
                    className = `${className} button--link`;
                    break;
                case "noClass":
                    className = '';
                    break;
                default:
                    break;
            }

            return className;
        },
    },
};
</script>

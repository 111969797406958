import axios from "axios";
import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false, size: 10 });

const axiosClient = axios.create({
    baseURL: `/api`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
});

axiosClient.interceptors.request.use(
    (config) => {
        NProgress.start();
        return config;
    },
    (error) => {
        NProgress.done();
        return Promise.reject(error);
    }
);


axiosClient.interceptors.response.use(
    (response) => {
        NProgress.done();
        return response;
    },
    (error) => {
        NProgress.done();

        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        if (!error.response) {
            console.error('Network error:', error);
            return Promise.reject(new Error('Network error, please try again later.'));
        }

        const { status } = error.response;

        if (status === 419) {
            window.location.reload();
        } else if (status === 401 || status === 403){
            console.error('Unauthorized access - Should redirect to login page');
        } else if (status >= 500) {
            console.error('Server error, please try again later.');
        }

        return Promise.reject(error);
    }
);

export default axiosClient;

<template>
    <svg
        id="Group_129"
        data-name="Group 129"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16.839"
        height="16.935"
        viewBox="0 0 16.839 16.935"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_72"
                    data-name="Rectangle 72"
                    width="16.839"
                    height="16.936"
                    fill="currentColor"
                />
            </clipPath>
        </defs>
        <g id="Group_128" data-name="Group 128" clip-path="url(#clip-path)">
            <path
                id="Path_66"
                data-name="Path 66"
                d="M77.8,8.453q0,3.238,0,6.475a2.135,2.135,0,0,1-.195.966,1.642,1.642,0,0,1-1.27.958,4.987,4.987,0,0,1-1.9-.022,1.7,1.7,0,0,1-1.374-1.593c-.024-.29-.018-.583-.018-.874q0-5.947,0-11.894c0-.241,0-.483.009-.724A1.682,1.682,0,0,1,74.52.085a4.693,4.693,0,0,1,2.05.058,1.584,1.584,0,0,1,1.1,1.038,2.12,2.12,0,0,1,.126.706c.009,2.189.005,4.377.005,6.566Zm-3.624.014h0q0,3.259,0,6.518a.642.642,0,0,0,.717.732c.372,0,.745,0,1.117,0a.6.6,0,0,0,.639-.539,1.2,1.2,0,0,0,.012-.21q0-1.893,0-3.787,0-4.1,0-8.208c0-.362.01-.724,0-1.086a.622.622,0,0,0-.65-.664c-.4-.011-.805-.011-1.207,0a.6.6,0,0,0-.621.6c-.009.11-.011.221-.011.332q0,3.153,0,6.307"
                transform="translate(-67 0.001)"
                fill="currentColor"
            />
            <path
                id="Path_67"
                data-name="Path 67"
                d="M150.833,36.434q0,2.633,0,5.266a2.155,2.155,0,0,1-.249,1.073,1.637,1.637,0,0,1-1.2.847,4.787,4.787,0,0,1-1.917-.017,1.7,1.7,0,0,1-1.373-1.593c-.023-.285-.018-.572-.018-.859q0-4.9,0-9.808a2.738,2.738,0,0,1,.133-.994,1.612,1.612,0,0,1,1.229-1.046,4.54,4.54,0,0,1,2.034,0,1.7,1.7,0,0,1,1.356,1.685c.011,1.816,0,3.632,0,5.447h0m-3.624.029h0q0,2.49,0,4.981c0,.151-.007.3,0,.453a.548.548,0,0,0,.506.564,13.7,13.7,0,0,0,1.431.016.506.506,0,0,0,.506-.459,5.848,5.848,0,0,0,.041-.81q0-4.6,0-9.192c0-.317.011-.634,0-.951a.617.617,0,0,0-.644-.655c-.4-.011-.805-.008-1.208,0a.556.556,0,0,0-.578.4,1.984,1.984,0,0,0-.056.536q-.006,2.558,0,5.117"
                transform="translate(-133.995 -26.773)"
                fill="currentColor"
            />
            <path
                id="Path_68"
                data-name="Path 68"
                d="M4.752,92.583c0,1.026.016,2.052-.006,3.078a1.7,1.7,0,0,1-.751,1.411A1.9,1.9,0,0,1,3,97.4a5.305,5.305,0,0,1-1.621-.064A1.723,1.723,0,0,1,.017,95.746C0,95.5,0,95.255,0,95.008,0,93.2,0,91.4.006,89.592a1.686,1.686,0,0,1,.726-1.465,2.02,2.02,0,0,1,1.034-.341,5.308,5.308,0,0,1,1.62.067,1.725,1.725,0,0,1,1.364,1.7c.013,1.011,0,2.022,0,3.033h0m-3.619.009h0q0,1.457,0,2.913a.636.636,0,0,0,.706.713q.559.006,1.117,0a.6.6,0,0,0,.646-.53,1.333,1.333,0,0,0,.014-.255q0-2.068.006-4.135c0-.553.011-1.107,0-1.66a.617.617,0,0,0-.645-.668q-.6-.017-1.208,0a.6.6,0,0,0-.623.6c-.009.11-.011.221-.011.332q0,1.343,0,2.687"
                transform="translate(0 -80.501)"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="9" cy="9" r="9" fill="currentColor" />
        <g clip-path="url(#g)">
            <path
                d="M6 9.106V5.528a.49.49 0 0 1 .209-.429.511.511 0 0 1 .8.339c.009.065.037.077.1.077h5.585c.07-.001.138.005.206.018a.379.379 0 0 1 .211.594c-.451.586-.888 1.184-1.33 1.777-.045.06-.118.118-.124.181-.006.063.08.113.124.171l1.13 1.517c.069.093.133.19.208.278a.384.384 0 0 1-.181.581.741.741 0 0 1-.227.035H7.142c-.121 0-.121 0-.121.123v1.879a.63.63 0 0 1-.064.3.53.53 0 0 1-.488.264.5.5 0 0 1-.448-.376.841.841 0 0 1-.021-.192V9.106Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="g">
                <path fill="#fff" d="M6 5h7.196v8.242H6z" />
            </clipPath>
        </defs>
    </svg>
</template>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30.662" height="30.661" viewBox="0 0 30.662 30.661">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_11" data-name="Rectangle 11" width="30.662" height="30.661" fill="fill-current"/>
            </clipPath>
        </defs>
        <g id="Group_15" data-name="Group 15" clip-path="url(#clip-path)">
            <path id="Path_13" data-name="Path 13" d="M21.606,30.657a22.736,22.736,0,0,1-4.253-.518,22.9,22.9,0,0,1-6.2-2.375,22,22,0,0,1-5.328-4.239,20.805,20.805,0,0,1-2.1-2.649A21.887,21.887,0,0,1,.537,13.387,22.075,22.075,0,0,1,0,8.613,8.388,8.388,0,0,1,1.106,4.4,8.619,8.619,0,0,1,6.065.382,7.939,7.939,0,0,1,8.478,0a.951.951,0,0,1,1,.677q1.347,3.37,2.694,6.74c.352.883.7,1.768,1.05,2.652a1.032,1.032,0,0,1,.091.625.886.886,0,0,1-.562.652c-.581.235-1.165.467-1.747.7l-2.218.891c-.236.095-.238.1-.219.348a8.777,8.777,0,0,0,.657,2.765,9.557,9.557,0,0,0,1.38,2.386,9.7,9.7,0,0,0,5.966,3.559c.308.054.623.073.935.11a.138.138,0,0,0,.169-.1q.7-1.777,1.417-3.551c.076-.189.145-.382.228-.568a.87.87,0,0,1,1.173-.493c.734.283,1.46.585,2.191.878q2.509,1.006,5.02,2.01c.754.3,1.51.6,2.264.9a.972.972,0,0,1,.7,1.06,8.581,8.581,0,0,1-7.06,8.276,9.236,9.236,0,0,1-2,.148" transform="translate(-0.001 0)" fill="fill-current"/>
        </g>
    </svg>
</template>

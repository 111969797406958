<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.902"
        height="18.902"
        viewBox="0 0 18 18"
    >
        <g data-name="Group 201">
            <path
                data-name="Path 43"
                d="m6.241 9.087 2.383 2.382 4.038-4.038"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.614" height="16.232" viewBox="0 0 17.614 16.232">
        <path
            fill="currentColor"
            data-name="Path 6109"
            d="M12.631 14.519h2.753a2.1 2.1 0 0 0 1.23-.306 2.14 2.14 0 0 0 1-1.869c.006-2.124 0-7.656 0-10.019v-.151a3 3 0 0 0-.022-.317 2.075 2.075 0 0 0-1.574-1.78A3.6 3.6 0 0 0 15.205 0H4.456a2.35 2.35 0 0 0-1.2.344A1.97 1.97 0 0 0 2.305 2.1v1.2a5 5 0 0 1 .92-.093h.03a2.3 2.3 0 0 1 .372.03V2.085a.7.7 0 0 1 .619-.706 2 2 0 0 1 .305-.024h10.96a.73.73 0 0 1 .762.764c.022.618.019 1.246.016 1.852v8.402a.745.745 0 0 1-.8.79h-2.823a2.4 2.4 0 0 1 .049.572 5.5 5.5 0 0 1-.084.786"
        />
        <path
            fill="currentColor"
            data-name="Path 6110"
            d="M10.861 14.805a3.27 3.27 0 0 0 .581-1.8.54.54 0 0 0-.375-.57l-.374-.147-.442-.175-1.809-.724-.264-.107-.032-.014-.5-.2a.5.5 0 0 0-.671.28q-.032.075-.06.148l-.126.316-.325.813-.048.12-.129-.016-.087-.008a3.28 3.28 0 0 1-2.033-1.214 3.2 3.2 0 0 1-.468-.812 3 3 0 0 1-.2-.753l-.017-.13 1.029-.413.356-.143a.51.51 0 0 0 .319-.372.56.56 0 0 0-.041-.341L5 8.179q-.117-.3-.235-.593l-.791-1.98-.18-.45a.53.53 0 0 0-.532-.367 3.1 3.1 0 0 0-.939.148 3.3 3.3 0 0 0-1.9 1.536A3.23 3.23 0 0 0 0 8.089a8.2 8.2 0 0 0 .2 1.763 8.1 8.1 0 0 0 1.177 2.766 8 8 0 0 0 .775.98 8.2 8.2 0 0 0 1.968 1.565 8.5 8.5 0 0 0 2.287.877 8.4 8.4 0 0 0 1.569.191h.116a3.4 3.4 0 0 0 .647-.058 3.27 3.27 0 0 0 2.122-1.369m-.507-1.39a2.15 2.15 0 0 1-.413.9 2.22 2.22 0 0 1-1.375.845 2.4 2.4 0 0 1-.438.039q-.094 0-.19-.007l-.181-.012c-.235-.016-.457-.03-.68-.063-.258-.038-.508-.1-.808-.171a7.1 7.1 0 0 1-3.169-1.85 7.1 7.1 0 0 1-1.564-2.375 6.6 6.6 0 0 1-.4-1.421 7 7 0 0 1-.1-1.326 2.13 2.13 0 0 1 .383-1.148 2.2 2.2 0 0 1 .7-.674 2.3 2.3 0 0 1 .69-.275l.14-.031.053.133q.468 1.178.938 2.355l.063.157-.157.063-.4.161-.167.067-.49.2a.53.53 0 0 0-.364.546 4.36 4.36 0 0 0 1.765 3.43 4.5 4.5 0 0 0 1.5.712 4 4 0 0 0 1.023.135h.007a.53.53 0 0 0 .531-.357l.344-.86.147-.368 2.644 1.057Z"
        />
        <path
            fill="currentColor"
            data-name="Path 6111"
            d="M13.415 3.475H6.557a.7.7 0 0 0-.173.024.43.43 0 0 0-.248.229 1.1 1.1 0 0 0-.055.823c.112.339.282.383.418.383h6.876a.6.6 0 0 0 .161-.023.44.44 0 0 0 .242-.229 1.1 1.1 0 0 0 .054-.81c-.111-.349-.283-.394-.42-.394"
        />
        <path
            fill="currentColor"
            data-name="Path 6112"
            d="M6.558 6.533a.7.7 0 0 0-.173.023.43.43 0 0 0-.247.229 1.1 1.1 0 0 0-.053.82c.113.339.282.383.418.383h6.877a.6.6 0 0 0 .161-.022.44.44 0 0 0 .242-.228 1.1 1.1 0 0 0 .054-.81c-.111-.35-.283-.395-.421-.395z"
        />
    </svg>
</template>

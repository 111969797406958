<template>
    <svg id="Group_148" data-name="Group 148" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.071" height="14.252" viewBox="0 0 12.071 14.252">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_82" data-name="Rectangle 82" width="12.071" height="14.252" fill="#32edaa"/>
            </clipPath>
        </defs>
        <g id="Group_146" data-name="Group 146" clip-path="url(#clip-path)">
            <path id="Path_75" data-name="Path 75" d="M35.673,0A3.805,3.805,0,0,1,39.615,3.66a3.836,3.836,0,0,1-7.663.355A3.845,3.845,0,0,1,35.673,0" transform="translate(-29.75 0)" fill="#32edaa"/>
            <path id="Path_76" data-name="Path 76" d="M12.023,106.652a6.063,6.063,0,0,0-.635-2.046,6.346,6.346,0,0,0-1.094-1.493.61.61,0,0,0-.246-.16c-1.282,2.177-6.233,2.372-7.991.013a.578.578,0,0,0-.268.141,3.191,3.191,0,0,0-.235.248,6.126,6.126,0,0,0-1.162,1.922,6.515,6.515,0,0,0-.385,1.835,1.8,1.8,0,0,0,.609,1.515,4.035,4.035,0,0,0,1.072.679,8.093,8.093,0,0,0,1.766.557c.376.078.759.129,1.141.172s.768.073,1.152.085a12.366,12.366,0,0,0,2.675-.221,8.314,8.314,0,0,0,2-.6,3.646,3.646,0,0,0,1.185-.813,1.618,1.618,0,0,0,.453-.974,4.028,4.028,0,0,0-.039-.862" transform="translate(-0.001 -95.874)" fill="#32edaa"/>
        </g>
    </svg>

</template>
<script setup>
</script>

<template>
    <svg
        id="Group_13"
        data-name="Group 13"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18.189"
        height="18.125"
        viewBox="0 0 18.189 18.125"
    >
        <path
            id="Path_12"
            data-name="Path 12"
            d="M0,12.36c0-1.6,0-3.2,0-4.793a2.089,2.089,0,0,1,.549-1.5,4.192,4.192,0,0,1,.59-.489C2.062,4.9,2.99,4.237,3.916,3.569Q5.393,2.5,6.871,1.439C7.447,1.022,8.02.6,8.6.184a.76.76,0,0,1,.992,0c.547.395,1.09.8,1.637,1.191Q12.707,2.442,14.191,3.5q.91.654,1.819,1.311c.4.289.8.579,1.2.866a2.2,2.2,0,0,1,.973,1.878c0,1.007,0,2.013,0,3.02q0,3.346,0,6.691a.835.835,0,0,1-.669.839,1.025,1.025,0,0,1-.207.015H11.54a.776.776,0,0,1-.807-.539,1.18,1.18,0,0,1-.039-.33q0-1.79,0-3.581A1.664,1.664,0,0,0,7.443,13.2a1.727,1.727,0,0,0-.071.516c0,1.2,0,2.4,0,3.6a.768.768,0,0,1-.614.793,1,1,0,0,1-.215.017H.843a.776.776,0,0,1-.787-.491A.757.757,0,0,1,.007,17.4C0,17.228,0,17.059,0,16.891q0-2.265,0-4.53Zm5.907,4.217c0-.033,0-.059,0-.086,0-.922-.007-1.844,0-2.767a3.645,3.645,0,0,1,.053-.6,3.171,3.171,0,0,1,3.959-2.477,3.134,3.134,0,0,1,2.048,1.789,3.241,3.241,0,0,1,.268,1.452c-.009.886,0,1.772,0,2.658,0,.06.019.08.078.078.093,0,.187,0,.28,0q1.972,0,3.944,0c.085,0,.1-.023.1-.106q0-2.925,0-5.85c0-1.013-.006-2.025,0-3.038a.8.8,0,0,0-.373-.725c-.281-.192-.553-.4-.828-.6q-1.693-1.229-3.387-2.458-1.353-.983-2.7-1.969c-.092-.067-.185-.133-.278-.2L9,1.733l-1.136.82L4.551,4.932C3.677,5.56,2.809,6.2,1.928,6.812a.914.914,0,0,0-.43.83c.011,2.914.005,5.829.005,8.743v.191Z"
            transform="translate(0 0)"
            fill="currentColor"
        />
    </svg>
</template>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="163.347"
        height="27.247"
        viewBox="0 0 163.347 27.247"
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 40"
                    fill="#32edaa"
                    d="M0 0h163.347v27.247H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 60">
            <g data-name="Group 59" clip-path="url(#a)" fill="#32edaa">
                <path
                    data-name="Path 45"
                    d="M44.291 2.894c5.171 0 7.905 3.011 7.905 8.708v15.5h3.076v-15.28C55.273 4.529 51.037 0 44.217 0S33.162 4.444 33.162 11.6v15.5h3.078V11.782c0-5.731 2.86-8.888 8.051-8.888"
                />
                <path
                    data-name="Rectangle 39"
                    d="M64.287.247h2.953v26.412h-2.953z"
                />
                <path
                    data-name="Path 46"
                    d="M102.849 26.707V.198h-2.668l-9.862 14.533-.12-.178L80.457.198h-2.668v26.509h2.977V5.994l9.136 13.291h.761l9.136-13.291v20.713Z"
                />
                <path
                    data-name="Path 47"
                    d="M129.308 14.795v-2.9h-14.517V3.104h16.148V.198h-19.2v26.509h19.376v-2.9h-16.324v-9.012Z"
                />
                <path
                    data-name="Path 48"
                    d="M163.347 13.38c0-7.638-5.908-13.182-14.049-13.182h-9.963v14.6h3.051V3.104h6.912c6.331 0 10.927 4.382 10.927 10.421s-4.494 10.279-10.927 10.279h-9.021v2.9h9.021c8.141 0 14.049-5.605 14.049-13.327"
                />
                <path
                    data-name="Path 49"
                    d="M23.132.247v14a10.128 10.128 0 0 1-20.256 0V6.054H0v8.189a13.004 13.004 0 0 0 26.009 0v-14Z"
                />
                <path
                    data-name="Path 50"
                    d="M6.076 14.036a6.969 6.969 0 1 0 13.937 0V.247H17.1v13.789a4.053 4.053 0 0 1-8.106 0V.247H0v2.916h6.076Z"
                />
            </g>
        </g>
    </svg>
</template>

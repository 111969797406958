<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.723"
        height="17.19"
        viewBox="0 0 14.723 17.19"
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 11"
                    fill="#32edaa"
                    d="M0 0h13.512v13.511H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 27">
            <g data-name="Group 26">
                <g
                    data-name="Group 15"
                    clip-path="url(#a)"
                    transform="translate(1.211 3.679)"
                >
                    <path
                        data-name="Path 13"
                        d="M3.991 13.509a10.019 10.019 0 0 0 1.874-.228 10.09 10.09 0 0 0 2.734-1.047 9.694 9.694 0 0 0 2.348-1.868 9.168 9.168 0 0 0 .921-1.166 9.645 9.645 0 0 0 1.408-3.3 9.728 9.728 0 0 0 .236-2.1 3.7 3.7 0 0 0-.487-1.857A3.8 3.8 0 0 0 10.839.168 3.5 3.5 0 0 0 9.776 0a.419.419 0 0 0-.439.3L8.149 3.269l-.463 1.169a.455.455 0 0 0-.04.276.39.39 0 0 0 .253.286l.77.309.977.392c.1.042.105.042.1.153a3.868 3.868 0 0 1-.29 1.218 4.212 4.212 0 0 1-.608 1.051 4.272 4.272 0 0 1-2.637 1.569c-.136.024-.275.032-.412.048a.061.061 0 0 1-.074-.04q-.31-.787-.626-1.569c-.033-.083-.064-.168-.1-.25a.383.383 0 0 0-.517-.217c-.323.125-.644.258-.965.387l-2.212.886c-.332.133-.666.262-1 .394a.428.428 0 0 0-.306.469 3.781 3.781 0 0 0 3.111 3.647 4.07 4.07 0 0 0 .879.065"
                        fill="currentColor"
                    />
                </g>
            </g>
            <path
                style="
                    transform: translate(-8px, -8px) rotate(180deg);
                    transform-origin: center;
                "
                data-name="Path 23"
                d="m2.121 7.609 2.744-2.744-2.744-2.744"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12.169"
        height="15.5"
        viewBox="0 0 12.169 15.5"
    >
        <g
            data-name="Group 8237"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="1.5"
        >
            <path data-name="Line 59" d="M6.06.75v11" />
            <path
                data-name="Path 6082"
                d="m11.107 7.138-5.024 5.024-5.024-5.024"
                stroke-linejoin="round"
            />
            <path data-name="Line 60" d="M1.06 14.75h10" />
        </g>
    </svg>
</template>

<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke-width="0" />
        <g stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M22 7H2m17 5H5m11 5H8"
            stroke="#000"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>

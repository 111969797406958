<template>
    <svg
        id="Group_19"
        data-name="Group 19"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16.839"
        height="18.241"
        viewBox="0 0 16.839 18.241"
    >
        <path
            id="Path_14"
            data-name="Path 14"
            d="M0,9.054Q0,5.585,0,2.116A2.121,2.121,0,0,1,1.751.034,3.311,3.311,0,0,1,2.276,0q6.17,0,12.341,0a2.108,2.108,0,0,1,1.277.354,2.179,2.179,0,0,1,.946,1.874c-.007,2.327,0,4.654,0,6.982q0,1.354,0,2.707a2.356,2.356,0,0,1-.188.981,2.176,2.176,0,0,1-.572.752l-4.593,3.986a2.26,2.26,0,0,1-1.117.567,2.647,2.647,0,0,1-.425.035c-2.587,0-5.173,0-7.76,0A2.107,2.107,0,0,1,.088,16.7,2.451,2.451,0,0,1,0,16.014Q0,12.534,0,9.054m15.432,2.17V2.207c0-.037,0-.073,0-.11a.7.7,0,0,0-.69-.694H2.107a.691.691,0,0,0-.7.7q0,7.014,0,14.028a.7.7,0,0,0,.709.707H9.12v-.143q0-1.682,0-3.364a2.1,2.1,0,0,1,1.6-2.036,3.052,3.052,0,0,1,.684-.066c1.293-.006,2.586,0,3.879,0Zm-.322,1.408c-.046,0-.07,0-.1,0q-1.907,0-3.813,0a.689.689,0,0,0-.68.687q0,1.594,0,3.189c0,.031,0,.061.007.1l4.581-3.971"
            transform="translate(0 0)"
            fill="currentColor"
        />
        <path
            id="Path_15"
            data-name="Path 15"
            d="M44.754,41.277H40.623a.711.711,0,0,1-.694-.39.684.684,0,0,1,.5-.99,1.478,1.478,0,0,1,.261-.023h8.22a.705.705,0,0,1,.7.4.683.683,0,0,1-.49.979,1.323,1.323,0,0,1-.25.023q-2.055,0-4.11,0"
            transform="translate(-36.346 -36.366)"
            fill="currentColor"
        />
        <path
            id="Path_16"
            data-name="Path 16"
            d="M44.742,79.16H40.622a.711.711,0,0,1-.694-.39.684.684,0,0,1,.5-.99,1.487,1.487,0,0,1,.261-.023h8.22a.7.7,0,0,1,.7.4.683.683,0,0,1-.491.979,1.33,1.33,0,0,1-.25.023q-2.06,0-4.121,0"
            transform="translate(-36.346 -70.917)"
            fill="currentColor"
        />
    </svg>
</template>

<template>
    <svg
        id="Group_8229"
        data-name="Group 8229"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="18.16"
        height="18.19"
        viewBox="0 0 18.16 18.19"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_321"
                    data-name="Rectangle 321"
                    width="18.16"
                    height="18.19"
                    fill="#32edaa"
                />
            </clipPath>
        </defs>
        <g id="Group_8228" data-name="Group 8228" clip-path="url(#clip-path)">
            <path
                id="Path_6078"
                data-name="Path 6078"
                d="M7.951,2.2c.016-.381-.029-.827.023-1.27a1.016,1.016,0,0,1,.771-.87A2.352,2.352,0,0,1,9.7.037,9.845,9.845,0,0,1,12.05.5,7.878,7.878,0,0,1,14,1.468,9.56,9.56,0,0,1,15.625,2.8a9.094,9.094,0,0,1,1.819,2.757,9.2,9.2,0,0,1,.68,2.773,8.132,8.132,0,0,1-.056,1.98,10.965,10.965,0,0,1-.346,1.577,8.29,8.29,0,0,1-.8,1.766,9.218,9.218,0,0,1-1.947,2.369,9.107,9.107,0,0,1-6.094,2.168A9.147,9.147,0,0,1,.672,12.525a8.642,8.642,0,0,1-.651-2.92A8.416,8.416,0,0,1,.3,6.739,9.252,9.252,0,0,1,2.141,3.221a4.47,4.47,0,0,1,.606-.636,1.279,1.279,0,0,1,.724-.3,1.058,1.058,0,0,1,.863.391A1.1,1.1,0,0,1,4.4,4.107c-.209.289-.473.529-.7.8A6.077,6.077,0,0,0,2.543,7.135a6.407,6.407,0,0,0-.286,2.3,6.793,6.793,0,0,0,3.9,5.827,7.187,7.187,0,0,0,2.635.625,6.341,6.341,0,0,0,2.175-.235,6.78,6.78,0,0,0,3.179-1.975,6.555,6.555,0,0,0,1.181-1.811,6.982,6.982,0,0,0,.534-2.153,7.292,7.292,0,0,0-.118-2.1,6.6,6.6,0,0,0-1.129-2.5,6.453,6.453,0,0,0-1.949-1.8,7.335,7.335,0,0,0-2.241-.868c-.187-.041-.213-.014-.215.178a9.658,9.658,0,0,1-.02.978,1.118,1.118,0,0,1-2.177.169,1.7,1.7,0,0,1-.057-.449c0-.35,0-.7,0-1.114"
                transform="translate(0 0)"
                fill="#32edaa"
            />
            <path
                id="Path_6079"
                data-name="Path 6079"
                d="M50.918,51.9a.765.765,0,0,1-.506-.191,10.968,10.968,0,0,1-1.11-1.055c-.576-.575-1.166-1.135-1.713-1.738a1.028,1.028,0,0,1-.339-.724.842.842,0,0,1,1.244-.711,1.338,1.338,0,0,1,.278.219l2.68,2.678a1.185,1.185,0,0,1,.241.314.847.847,0,0,1-.775,1.207"
                transform="translate(-41.859 -41.973)"
                fill="#32edaa"
            />
        </g>
    </svg>
</template>

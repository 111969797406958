<template>
    <svg
        id="Group_8231"
        data-name="Group 8231"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="15.885"
        height="18.18"
        viewBox="0 0 15.885 18.18"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_322"
                    data-name="Rectangle 322"
                    width="15.885"
                    height="18.18"
                    fill="#32edaa"
                />
            </clipPath>
        </defs>
        <g
            id="Group_8230"
            data-name="Group 8230"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
        >
            <path
                id="Path_6080"
                data-name="Path 6080"
                d="M0,10.176C0,8.3,0,6.428,0,4.554a2.159,2.159,0,0,1,1.033-1.91,2.532,2.532,0,0,1,1.321-.38c.369-.01.738,0,1.107,0,.209,0,.221-.011.222-.226,0-.379,0-.757,0-1.136A.843.843,0,0,1,4.508,0a.74.74,0,0,1,.71.363,1.12,1.12,0,0,1,.166.608c0,.369,0,.738,0,1.107,0,.133.034.183.174.183q2.385-.006,4.771,0c.147,0,.179-.066.179-.187,0-.393,0-.785,0-1.178a.844.844,0,0,1,.819-.9.742.742,0,0,1,.7.352A1.094,1.094,0,0,1,12.2.96c0,.369,0,.738,0,1.107,0,.192,0,.193.187.193.336,0,.672,0,1.008,0a3.707,3.707,0,0,1,.69.075,2.3,2.3,0,0,1,1.8,2.238c0,1.642,0,3.284,0,4.926q0,3.137,0,6.274a2.37,2.37,0,0,1-1.068,2.051,2.434,2.434,0,0,1-1.213.35c-.122.011-.246,0-.369,0H2.486A2.464,2.464,0,0,1,.76,17.57,2.273,2.273,0,0,1,0,15.826C0,13.942,0,12.059,0,10.176ZM7.935,6.821H1.887c-.2,0-.2,0-.2.2q0,4.152,0,8.3c0,.189,0,.379,0,.568a.53.53,0,0,0,.491.546,2.314,2.314,0,0,0,.339.023H13.436c.052,0,.1,0,.156,0a.58.58,0,0,0,.6-.6c0-.052,0-.1,0-.156q0-2.818,0-5.636c0-1.041.013-2.081-.01-3.122,0-.08-.033-.114-.105-.119-.052,0-.1,0-.156,0H7.935"
                transform="translate(0 0)"
                fill="#32edaa"
            />
        </g>
    </svg>
</template>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10.914"
        height="10.914"
        viewBox="0 0 10.914 10.914"
    >
        <g
            id="Group_182"
            data-name="Group 182"
            transform="translate(-683.793 -630.293)"
        >
            <line
                id="Line_6"
                data-name="Line 6"
                x2="9.5"
                y2="9.5"
                transform="translate(684.5 631)"
                fill="none"
                stroke="#ed3232"
                stroke-width="2"
            />
            <line
                id="Line_7"
                data-name="Line 7"
                x1="9.5"
                y2="9.5"
                transform="translate(684.5 631)"
                fill="none"
                stroke="#ed3232"
                stroke-width="2"
            />
        </g>
    </svg>
</template>

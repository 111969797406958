<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="22"
        viewBox="0 0 26 22"
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 325"
                    fill="#fff"
                    d="M0 0h17.925v15.816H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 210">
            <g
                data-name="Group 210"
                clip-path="url(#a)"
                transform="translate(7.769 6.786)"
            >
                <path
                    data-name="Path 83"
                    d="M17.918 3.183a2.934 2.934 0 0 0-.291-1.3A3.121 3.121 0 0 0 14.679.011C12.733-.011 10.786.005 8.84.005H3.311a3.57 3.57 0 0 0-.911.1A3.212 3.212 0 0 0 0 3.212V12.6a3.213 3.213 0 0 0 3.21 3.22h11.448a3.71 3.71 0 0 0 .742-.08 3.142 3.142 0 0 0 2.513-3.074c.023-2.151.006-7.322.006-9.473m-2.651 7.129a.6.6 0 0 1-.536.4.643.643 0 0 1-.546-.216q-1.158-1.16-2.318-2.318c-.113-.113-.222-.228-.358-.368-.111.122-.209.235-.313.341-.281.285-.56.57-.848.848a1.959 1.959 0 0 1-1.581.579 1.838 1.838 0 0 1-1.161-.544 142.6 142.6 0 0 1-1.1-1.1 1.2 1.2 0 0 1-.086-.109c-.043.04-.071.063-.1.088l-2.312 2.301c-.111.111-.218.226-.335.33a.635.635 0 0 1-1.046-.316.7.7 0 0 1 .22-.662q.566-.565 1.129-1.132l1.469-1.47.041-.044c-.026-.027-.049-.054-.074-.079L3.223 4.66a.661.661 0 0 1-.1-.847.636.636 0 0 1 .909-.141 2.471 2.471 0 0 1 .233.219Q6.2 5.826 8.139 7.762c.108.108.211.222.318.332a.65.65 0 0 0 .989.005l4.344-4.347a.634.634 0 0 1 .7-.171.621.621 0 0 1 .273.978 6.419 6.419 0 0 1-.494.521q-.888.893-1.78 1.782c-.021.021-.038.045-.064.076.064.06.125.116.183.175l2.225 2.227c.1.1.206.209.3.319a.618.618 0 0 1 .128.651"
                    fill="#fff"
                />
            </g>
            <path
                data-name="Path 85"
                d="m2.121 9.027 3.452-3.453-3.452-3.452"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>

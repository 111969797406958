import { formatDate } from "@/mixins/date";
import translations from "./translations.json";
import moment from 'moment';
import 'moment/dist/locale/pl';

export const debug = (...args) => {
    if (window && window.debug) {
        console.log(...args);
    }
};

export const debounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

export const wrapSearchNeedle = (haystack, needle) => {
    if (!needle) return haystack;
    const reg = /\b[A-Z a-z]\b/g
    const modified = needle.replace(reg, function (match) {
        return match.toLowerCase();
    });
    return haystack?.replaceAll(modified ? modified : '', `<span class="bg-gold-300">$&</span>`);
};
//gm
export const appendFiltersToParams = (filters, params, isGroup) => {
    filters.forEach((filter) => {
        if (filter.type && filter.type !== "date") {
            params.append(
                `filter${isGroup ? "[calls]" : ""}[type]`,
                filter.type
            );
        }

        if ("called_back" in filter) {
            params.append(`filter[called_back]`, filter.called_back);
        }

        if (filter.status) {
            params.append(
                `filter${isGroup ? "[calls]" : ""}[status]`,
                filter.status
            );
        }

        if (filter.start && filter.end) {
            const dateFormat = "YYYY-MM-DD";
            params.append(
                `filter${isGroup ? "[calls]" : ""}[created_between]`,
                `${formatDate(filter.start, dateFormat)},${formatDate(
                    filter.end,
                    dateFormat
                )}`
            );
        }
    });
};


export const date = (value) => {
    if (!value) return "";
    return value.substring(0, 10).replaceAll("-", "/");
}
export const translate = (value, ...props) => {
    const group = props.reduce((acc, val) => {
        return acc[val];
    }, translations);
    return group ? group[value] : "";
}

export function formatPhoneNumber(phone) {
    if (!phone) return '';

    if (phone.length <= 12) {
        const firstPart = phone.slice(0, 3);
        const rest = phone.slice(3).match(/.{1,3}/g) || [];
        return `${firstPart} ${rest.join(' ')}`;
    } else {
        const firstPart = phone.slice(0, 4);
        const rest = phone.slice(4).match(/.{1,3}/g) || [];
        return `${firstPart} ${rest.join(' ')}`;
    }
}

export const formatDateHuman = (date, format) => {
    moment.locale('pl');
    const parsedDate = moment(date);

    if (format) {
        return parsedDate.format(format);
    }

    const currentYear = moment().year();
    const formattedDate = parsedDate.format(parsedDate.year() < currentYear ? 'D MMM YYYY / HH:mm:ss' : 'D MMM / HH:mm:ss');

    return formattedDate;
}

export const formatSeconds = (seconds) => {
    if (!seconds) {
        return '-';
    }

    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}m ${remainingSeconds}s`;
}

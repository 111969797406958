<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="29.444" height="29.444" viewBox="0 0 29.444 29.444">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_364" data-name="Rectangle 364" width="12.353" height="14.717" fill="#131338"/>
            </clipPath>
        </defs>
        <g id="pesel-ikona" transform="translate(-603.278 -258.056)">
            <rect id="Rectangle_76" data-name="Rectangle 76" width="29.444" height="29.444" rx="8" transform="translate(603.278 258.056)" fill="#131338" opacity="0.07"/>
            <g id="Group_8291" data-name="Group 8291" transform="translate(612.072 266.56)">
                <g id="Group_8290" data-name="Group 8290" clip-path="url(#clip-path)">
                    <path id="Path_6089" data-name="Path 6089" d="M82.091,107.632l1.173,1.172,2.474-2.47a.494.494,0,0,1,.7,0l.035.035a.494.494,0,0,1,0,.7l-2.83,2.825a.494.494,0,0,1-.7,0l-1.558-1.555a.494.494,0,0,1,0-.7l.007-.007a.494.494,0,0,1,.7,0" transform="translate(-77.758 -101.638)" fill="#131338"/>
                    <path id="Path_6090" data-name="Path 6090" d="M12.352,0H0S0,5.257,0,6.756c0,.229.009.46.03.688a6.642,6.642,0,0,0,1.443,3.569,8.1,8.1,0,0,0,1.7,1.531c1,.716,1.992,1.444,3,2.173.034-.023,2.3-1.649,3.4-2.47a6.589,6.589,0,0,0,2.772-5.476c.011-1.5.007-6.771.007-6.771M11.317,6.745a5.571,5.571,0,0,1-1.909,4.3c-.485.432-1.036.792-1.561,1.179-.551.406-1.107.8-1.678,1.219-1.039-.764-2.1-1.487-3.092-2.286a5.379,5.379,0,0,1-1.987-3.6,6,6,0,0,1-.057-.782c-.005-1.5,0-4.26,0-5.763V1H11.319V1.03c0,1.486.007,4.228,0,5.715" fill="#131338"/>
                </g>
            </g>
        </g>
    </svg>
</template>

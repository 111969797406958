<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="15"
        viewBox="0 0 13 15"
    >
        <path
            data-name="Polygon 10"
            d="M10 5.768a2 2 0 0 1 0 3.464l-7 4.037a2 2 0 0 1-3-1.732V3.463A2 2 0 0 1 3 1.73Z"
            fill="currentColor"
        />
    </svg>
</template>

<template>
    <svg
        id="Group_7"
        data-name="Group 7"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="15.365"
        height="18.141"
        viewBox="0 0 15.365 18.141"
    >
        <path
            id="Path_7"
            data-name="Path 7"
            d="M7.91,110.363a16.921,16.921,0,0,0,2.269-.19,9.584,9.584,0,0,0,1.849-.446,5.12,5.12,0,0,0,1.468-.749c.074-.058.142-.124.211-.189a.8.8,0,0,0,.263-.642,6.262,6.262,0,0,0-1.5-3.963c-.112-.133-.243-.251-.364-.375a.682.682,0,0,1-.181-.722.692.692,0,0,1,1.176-.252,8.079,8.079,0,0,1,1.393,1.9,7.716,7.716,0,0,1,.808,2.6,5.127,5.127,0,0,1,.05,1.1,2.061,2.061,0,0,1-.577,1.24,4.641,4.641,0,0,1-1.509,1.035,10.582,10.582,0,0,1-2.549.761,15.748,15.748,0,0,1-3.4.281c-.49-.015-.98-.054-1.467-.108s-.974-.119-1.453-.219a10.3,10.3,0,0,1-2.248-.709,5.138,5.138,0,0,1-1.365-.865,2.3,2.3,0,0,1-.775-1.929A8.3,8.3,0,0,1,.5,105.59a7.8,7.8,0,0,1,1.48-2.447,4.115,4.115,0,0,1,.3-.316.688.688,0,0,1,.956,0,.677.677,0,0,1,.042.953c-.231.28-.482.545-.694.838a5.963,5.963,0,0,0-1.127,2.8c-.033.27-.034.543-.052.815a.61.61,0,0,0,.14.413,2.5,2.5,0,0,0,.829.66,7.752,7.752,0,0,0,2.373.8,16.7,16.7,0,0,0,3.166.267"
            transform="translate(0 -93.62)"
            fill="currentColor"
        />
        <path
            id="Path_8"
            data-name="Path 8"
            d="M36.691,0A4.844,4.844,0,0,1,41.708,4.66a4.882,4.882,0,0,1-9.754.452A4.894,4.894,0,0,1,36.691,0m.09,8.363a3.487,3.487,0,1,0-3.434-3.67,3.484,3.484,0,0,0,3.434,3.67"
            transform="translate(-29.151 -0.001)"
            fill="currentColor"
        />
    </svg>
</template>

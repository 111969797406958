<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="570.999"
        height="592.757"
        viewBox="0 0 570.999 592.757"
    >
        <g
            id="Group_153"
            data-name="Group 153"
            transform="translate(-1191.802 -210)"
            opacity="0.05"
        >
            <path
                id="Path_71"
                data-name="Path 71"
                d="M507.846.323V307.6c0,122.59-99.757,222.33-222.347,222.33-122.607,0-222.347-99.74-222.347-222.33V127.806H0V307.6C0,465.026,128.071,593.08,285.5,593.08,442.911,593.08,571,465.026,571,307.6V.323Z"
                transform="translate(1191.802 209.677)"
                fill="#32edaa"
            />
            <path
                id="Path_72"
                data-name="Path 72"
                d="M133.385,303.057c0,84.355,68.617,152.99,152.99,152.99,84.355,0,152.99-68.634,152.99-152.99V.323h-64.01V303.057a88.979,88.979,0,0,1-177.958,0V.323H0V64.334H133.385Z"
                transform="translate(1191.802 209.677)"
                fill="#32edaa"
            />
        </g>
    </svg>
</template>

<template>
    <svg
        width="26"
        height="23"
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.687 9.969a2.935 2.935 0 0 0-.291-1.3 3.121 3.121 0 0 0-2.948-1.872c-1.946-.022-3.893-.006-5.839-.006H11.08a3.57 3.57 0 0 0-.911.1 3.212 3.212 0 0 0-2.4 3.107v9.388a3.213 3.213 0 0 0 3.21 3.22h11.448c.25-.002.498-.028.742-.08a3.142 3.142 0 0 0 2.513-3.074c.023-2.151.006-7.322.006-9.473m-2.651 7.129a.6.6 0 0 1-.536.4.643.643 0 0 1-.546-.216l-2.318-2.318c-.113-.113-.222-.228-.358-.368-.111.122-.209.235-.313.341-.281.285-.56.57-.848.848a1.958 1.958 0 0 1-1.581.579 1.838 1.838 0 0 1-1.161-.544 140.84 140.84 0 0 1-1.1-1.1 1.173 1.173 0 0 1-.086-.109c-.043.04-.071.063-.1.088l-2.312 2.301c-.111.111-.218.226-.335.33a.635.635 0 0 1-1.046-.316.7.7 0 0 1 .22-.662c.377-.377.754-.754 1.129-1.132l1.469-1.47.041-.044c-.026-.027-.049-.054-.074-.079l-2.19-2.191a.66.66 0 0 1-.1-.847.636.636 0 0 1 .909-.141c.082.068.16.141.233.219l3.874 3.871c.108.108.211.222.318.332a.649.649 0 0 0 .989.005l4.344-4.347a.634.634 0 0 1 .7-.171.621.621 0 0 1 .273.978c-.155.183-.32.357-.494.521-.592.595-1.185 1.19-1.78 1.782-.021.021-.038.045-.064.076.064.06.125.116.183.175l2.225 2.227c.1.1.206.209.3.319a.619.619 0 0 1 .128.651"
            fill="#fff"
        />
        <path
            d="M5 9 2 5.5 5 2"
            stroke="#fff"
            stroke-width="3"
            stroke-linecap="round"
        />
    </svg>
</template>

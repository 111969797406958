<template>
    <svg id="Group_164" data-name="Group 164" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.83" height="13.898" viewBox="0 0 12.83 13.898">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_12" data-name="Rectangle 12" width="12.83" height="13.898" fill="#34c3ea"/>
            </clipPath>
        </defs>
        <g id="Group_18" data-name="Group 18" clip-path="url(#clip-path)">
            <path id="Path_14" data-name="Path 14" d="M0,6.9Q0,4.255,0,1.612A1.616,1.616,0,0,1,1.334.026,2.522,2.522,0,0,1,1.734,0q4.7,0,9.4,0a1.606,1.606,0,0,1,.973.269A1.66,1.66,0,0,1,12.83,1.7c-.005,1.773,0,3.546,0,5.319q0,1.031,0,2.063a1.8,1.8,0,0,1-.143.747,1.658,1.658,0,0,1-.436.573l-3.5,3.037a1.722,1.722,0,0,1-.851.432,2.017,2.017,0,0,1-.324.026c-1.971,0-3.941,0-5.912,0A1.605,1.605,0,0,1,.067,12.72,1.868,1.868,0,0,1,0,12.2Q0,9.55,0,6.9M11.759,8.552V1.682c0-.028,0-.056,0-.084a.531.531,0,0,0-.526-.528H1.605A.526.526,0,0,0,1.07,1.6q0,5.344,0,10.688a.533.533,0,0,0,.54.538H6.949v-.109q0-1.282,0-2.563A1.6,1.6,0,0,1,8.168,8.6a2.325,2.325,0,0,1,.521-.05c.985,0,1.97,0,2.956,0Zm-.245,1.073-.073,0H8.536a.525.525,0,0,0-.518.523q0,1.215,0,2.429c0,.023,0,.047.005.076l3.49-3.026" transform="translate(0 0)" fill="#34c3ea"/>
            <path id="Path_15" data-name="Path 15" d="M43.587,40.942H40.439a.542.542,0,0,1-.529-.3.521.521,0,0,1,.379-.754,1.126,1.126,0,0,1,.2-.018h6.263a.537.537,0,0,1,.531.305.52.52,0,0,1-.373.746,1.008,1.008,0,0,1-.19.018H43.587" transform="translate(-37.181 -37.201)" fill="#34c3ea"/>
            <path id="Path_16" data-name="Path 16" d="M43.578,78.825h-3.14a.542.542,0,0,1-.529-.3.521.521,0,0,1,.38-.754,1.133,1.133,0,0,1,.2-.018h6.263a.537.537,0,0,1,.531.305.52.52,0,0,1-.374.746,1.013,1.013,0,0,1-.19.018h-3.14" transform="translate(-37.18 -72.545)" fill="#34c3ea"/>
        </g>
    </svg>

</template>

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/pl";
dayjs.locale("pl");
dayjs.extend(updateLocale);
export const formatDate = (date, format) => {
    const dayjsDate = dayjs(date);
    const formatedDate = dayjsDate.isValid() ? dayjsDate.format(format) : "-";
    return formatedDate;
};

export default {
    mounted() {
        dayjs.extend(duration);
        dayjs.extend(utc);
    },
    methods: {
        formatDate(date, format = "DD/MM/YYYY, HH:mm") {
            return formatDate(date, format);
        },
        parseSecondsToTime(seconds) {
            const oneMinuteAgo = dayjs().subtract(seconds, "second");
            const dur = dayjs.duration(dayjs().diff(oneMinuteAgo));
            return dayjs.utc(dur.asMilliseconds()).format("HH:mm:ss");
        },
        minutesToSeconds(minutes) {
            return dayjs
                .duration(minutes, "minutes")
                .format("H[h] m[m]")
                .replace(/\b0+[a-z]+\s*/gi, "")
                .trim();
        },
        timer(seconds) {
            const time = dayjs.duration(seconds, "seconds").$d;
            const hours = String(dayjs.duration(seconds, "seconds").asHours()).split('.')[0];
            const asHours = +hours === 0 ? "00" : +hours > 9 ? +hours : dayjs.duration(+hours, "hours").format("HH");
            const asMinutes = dayjs.duration(time.minutes, "minutes").format("mm");
            const asSeconds = dayjs.duration(time.seconds, "seconds").format("ss");
            return {
                asHours: asHours,
                minutes: asMinutes,
                seconds: asSeconds
            }
        },
        minutesToTime(minutes) {
            return dayjs
                .duration(minutes, "minutes")
                .format("H[h] m[m]")
                .replace(/\b0+[a-z]+\s*/gi, "")
                .trim();
        },
        splitTime(time) {
            const hoursFormat = time && this.timer(time).asHours !== 0 ? this.timer(time).asHours : "00";
            const minutesFormat = time ? this.timer(time).minutes : "00";
            const secondsFormat = time ? this.timer(time).seconds : "00";
            return { hoursFormat, minutesFormat, secondsFormat };
        },
        subtractDate(date, amount = 1, period = "day") {
            return dayjs(date).subtract(amount, period);
        },
        addDate(date, amount = 1, period = "day") {
            return dayjs(date).add(amount, period);
        },
    },
};

<template>
    <svg id="Group_153" data-name="Group 153" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.414" height="16.31" viewBox="0 0 20.414 16.31" fill="currentColor">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_84" data-name="Rectangle 84" width="20.414" height="16.31" fill="currentColor"/>
            </clipPath>
        </defs>
        <g id="Group_152" data-name="Group 152" clip-path="url(#clip-path)">
            <path id="Path_77" data-name="Path 77" d="M1.52,5.2c0,.083,0,.145,0,.207,0,.342.007.685.01,1.027a.515.515,0,0,0,.138.346,2.414,2.414,0,0,0,.834.587,7.113,7.113,0,0,0,1.84.531,11.094,11.094,0,0,0,2.191.133c.219-.008.437-.036.656-.037a.723.723,0,0,1,.685.4.761.761,0,0,1-.608,1.123,13.4,13.4,0,0,1-2.4,0,9.6,9.6,0,0,1-2.685-.636c-.177-.07-.346-.161-.519-.243l-.14-.065c0,.344.019.67,0,.994a.909.909,0,0,0,.449.847,4.734,4.734,0,0,0,1.608.7A9.044,9.044,0,0,0,5.8,11.4c.525.007,1.051-.029,1.575-.061a.771.771,0,0,1,.822.67.744.744,0,0,1-.572.823,5.913,5.913,0,0,1-.985.1,12.22,12.22,0,0,1-1.969-.069A8.812,8.812,0,0,1,1.653,12a.925.925,0,0,0-.1-.042c-.006,0-.015,0-.033,0,0,.319.02.641,0,.959a.912.912,0,0,0,.448.859,4.538,4.538,0,0,0,1.517.676,8.35,8.35,0,0,0,1.8.287c.322.015.645.027.967.029a10.913,10.913,0,0,0,2.473-.295.729.729,0,0,1,.856.515.717.717,0,0,1-.416.9,4.8,4.8,0,0,1-.749.194,11.747,11.747,0,0,1-3.545.161,9.223,9.223,0,0,1-3.157-.838A3.06,3.06,0,0,1,.2,14.005a1.949,1.949,0,0,1-.2-.853c0-1.222,0-2.444,0-3.667,0-2.078,0-4.156-.007-6.234A2.242,2.242,0,0,1,.781,1.524,4.855,4.855,0,0,1,2.67.509,11.878,11.878,0,0,1,6.931.025,10.7,10.7,0,0,1,9.848.606a4.518,4.518,0,0,1,1.543.864,2.284,2.284,0,0,1,.816,1.442A1.842,1.842,0,0,1,11.9,4.149a3.384,3.384,0,0,1-1.56,1.235,8.781,8.781,0,0,1-2.111.627,12.365,12.365,0,0,1-3.345.12,10.055,10.055,0,0,1-2.631-.6c-.2-.079-.4-.179-.6-.269L1.52,5.2M6.3,4.678c.3-.019.6-.03.894-.06A8.146,8.146,0,0,0,9.5,4.087a2.822,2.822,0,0,0,1.057-.678.394.394,0,0,0,.022-.577,1.294,1.294,0,0,0-.282-.273,5.549,5.549,0,0,0-.773-.445,7.885,7.885,0,0,0-2.592-.552,10.984,10.984,0,0,0-2.821.144,5.9,5.9,0,0,0-1.72.554A2.4,2.4,0,0,0,1.67,2.8a.422.422,0,0,0,0,.612,1.293,1.293,0,0,0,.116.12,3.323,3.323,0,0,0,1.183.654,10.124,10.124,0,0,0,3.33.49" transform="translate(0 0)"/>
            <path id="Path_78" data-name="Path 78" d="M101.48,62.914a5.6,5.6,0,1,1,5.619-5.6,5.628,5.628,0,0,1-5.619,5.6m-4.067-5.569a4.084,4.084,0,1,0,4.059-4.124,4.082,4.082,0,0,0-4.059,4.124" transform="translate(-86.685 -46.743)"/>
            <path id="Path_79" data-name="Path 79" d="M139.179,78.524a1.7,1.7,0,0,1-1.074,1.57.145.145,0,0,0-.111.162.618.618,0,0,1-.615.629.592.592,0,0,1-.583-.637c0-.1-.041-.121-.112-.15a1.617,1.617,0,0,1-.8-.658.608.608,0,0,1,.924-.785,1.756,1.756,0,0,1,.12.148.617.617,0,0,0,.845.094.461.461,0,0,0,.165-.5.558.558,0,0,0-.479-.376,3.4,3.4,0,0,1-.7-.125,1.69,1.69,0,0,1-.78-2.659,1.566,1.566,0,0,1,.723-.528c.075-.026.086-.069.087-.133a.608.608,0,0,1,.167-.447.587.587,0,0,1,.646-.159.554.554,0,0,1,.387.536.219.219,0,0,0,.16.225,1.58,1.58,0,0,1,.773.667.613.613,0,0,1-.176.834.593.593,0,0,1-.832-.174.6.6,0,0,0-.654-.259.495.495,0,0,0,0,.982c.137.022.277.029.415.045a1.751,1.751,0,0,1,1.5,1.7" transform="translate(-122.592 -66.836)"/>
        </g>
    </svg>
</template>
<script setup>
</script>

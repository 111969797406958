<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.046"
        height="5.937"
        viewBox="0 0 9.046 5.937"
    >
        <path
            id="Path_79"
            data-name="Path 79"
            d="M0,0,3.109,3.109,6.218,0"
            transform="translate(7.632 4.523) rotate(180)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="2"
        />
    </svg>
</template>

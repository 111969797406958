<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16.839"
        height="18.55"
        viewBox="0 0 16.839 18.55"
    >
        <g
            id="Group_21"
            data-name="Group 21"
            transform="translate(16.839) rotate(90)"
        >
            <path
                id="Path_17"
                data-name="Path 17"
                d="M9.27,16.831c-1.083,0-2.166.023-3.248-.007a2.738,2.738,0,0,1-2.406-1.408Q1.992,12.652.406,9.866A2.779,2.779,0,0,1,.42,6.949q1.148-1.994,2.3-3.985c.3-.524.6-1.054.92-1.568A2.748,2.748,0,0,1,6.058.017c2.15-.02,4.3-.027,6.45,0a2.745,2.745,0,0,1,2.43,1.4c.494.826.966,1.665,1.448,2.5.588,1.017,1.182,2.031,1.76,3.053a2.8,2.8,0,0,1-.013,2.921c-.807,1.428-1.638,2.843-2.459,4.263-.242.419-.486.838-.727,1.258a2.846,2.846,0,0,1-1.433,1.211,2.727,2.727,0,0,1-1.07.206H9.27Zm-.04-1.2h0c1.086,0,2.172.011,3.258,0a1.622,1.622,0,0,0,1.455-.857c.4-.687.787-1.379,1.184-2.066.673-1.163,1.358-2.319,2.02-3.489a1.6,1.6,0,0,0,.028-1.531c-.077-.163-.184-.312-.275-.469q-1.088-1.878-2.174-3.757c-.28-.484-.544-.977-.833-1.456a1.559,1.559,0,0,0-1.221-.761,12.023,12.023,0,0,0-1.238-.021q-2.671,0-5.342,0a1.488,1.488,0,0,0-.6.109,1.568,1.568,0,0,0-.78.6c-.43.733-.849,1.472-1.275,2.207C2.76,5.3,2.081,6.454,1.416,7.618a1.6,1.6,0,0,0-.03,1.554c.1.21.237.4.355.607q.862,1.494,1.722,2.989c.357.62.712,1.241,1.074,1.859a2.5,2.5,0,0,0,.337.492,1.665,1.665,0,0,0,1.249.508c1.036,0,2.072,0,3.108,0"
                transform="translate(0 0)"
                fill="currentColor"
            />
            <path
                id="Path_18"
                data-name="Path 18"
                d="M83.1,68.209a3.553,3.553,0,0,1-7.107.048,3.553,3.553,0,1,1,7.107-.048m-3.554,2.372a2.354,2.354,0,1,0-2.359-2.421,2.345,2.345,0,0,0,2.359,2.421"
                transform="translate(-70.27 -59.804)"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<template>
    <svg id="Group_8219" data-name="Group 8219" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.617" height="12.015" viewBox="0 0 13.617 12.015">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_325" data-name="Rectangle 325" width="13.617" height="12.015" fill="#131338"/>
            </clipPath>
        </defs>
        <g id="Group_210" data-name="Group 210" clip-path="url(#clip-path)">
            <path id="Path_83" data-name="Path 83" d="M13.612,2.418a2.229,2.229,0,0,0-.221-.991A2.371,2.371,0,0,0,11.151.008C9.673-.009,8.194,0,6.716,0h-4.2A2.712,2.712,0,0,0,1.823.08,2.44,2.44,0,0,0,0,2.44C0,4.052,0,7.956,0,9.568a2.441,2.441,0,0,0,2.438,2.446q4.348,0,8.7,0a2.818,2.818,0,0,0,.564-.061,2.387,2.387,0,0,0,1.909-2.336c.018-1.634,0-5.562,0-7.2M11.6,7.834a.458.458,0,0,1-.407.307.488.488,0,0,1-.414-.164Q9.9,7.1,9.016,6.216c-.086-.086-.169-.173-.272-.279-.085.093-.159.179-.238.259-.213.216-.426.433-.644.644a1.488,1.488,0,0,1-1.2.44,1.4,1.4,0,0,1-.882-.413c-.281-.277-.559-.557-.838-.837a.913.913,0,0,1-.065-.083c-.033.03-.054.048-.073.067L3.046,7.767c-.084.084-.165.172-.254.25A.482.482,0,0,1,2,7.777a.528.528,0,0,1,.167-.5q.43-.429.858-.86L4.138,5.3l.031-.033c-.019-.021-.037-.041-.056-.06L2.449,3.54A.5.5,0,0,1,2.374,2.9a.483.483,0,0,1,.69-.107,1.877,1.877,0,0,1,.177.166L6.183,5.9c.082.082.16.169.241.252a.494.494,0,0,0,.751,0l3.3-3.3a.481.481,0,0,1,.535-.13.471.471,0,0,1,.207.743,4.876,4.876,0,0,1-.376.4q-.675.678-1.352,1.354c-.016.016-.029.034-.049.058.048.046.095.088.139.133L11.271,7.1c.079.079.157.159.23.243a.47.47,0,0,1,.1.495" transform="translate(0 0)" fill="#131338"/>
        </g>
    </svg>

</template>

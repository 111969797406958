<template>
    <component
        class="transform scale-75 origin-left sm:scale-100"
        v-if="icons[icon]"
        :is="icons[icon]"
        :style="`min-width: ${w}px; max-width: ${w}px; height: ${h}px`"
    />
</template>

<script>
import LogoIcon from "@/elements/v2/icons/LogoIcon";
import ChevronIcon from "@/elements/v2/icons/ChevronIcon";
import PersonIcon from "@/elements/v2/icons/PersonIcon";
import PersonFilledIcon from "@/elements/v2/icons/PersonFilledIcon";
import BellIcon from "@/elements/v2/icons/BellIcon";
import ChartIcon from "@/elements/v2/icons/ChartIcon";
import SettingsIcon from "@/elements/v2/icons/SettingsIcon";
import HomeIcon from "@/elements/v2/icons/HomeIcon";
import EnvelopeIcon from "@/elements/v2/icons/EnvelopeIcon";
import MenuIcon from "@/elements/v2/icons/MenuIcon";
import PhoneIcon from "@/elements/v2/icons/PhoneIcon";
import CallsListIcon from "@/elements/v2/icons/CallsListIcon";
import SearchIcon from "@/elements/v2/icons/SearchIcon";
import PencilIcon from "@/elements/v2/icons/PencilIcon";
import CheckmarkIcon from "@/elements/v2/icons/CheckmarkIcon";
import CloseIcon from "@/elements/v2/icons/CloseIcon";
import UnimedWatermarkIcon from "@/elements/v2/icons/UnimedWatermarkIcon";
import CallAnswerIcon from "@/elements/v2/icons/CallAnswerIcon";
import CallRejectIcon from "@/elements/v2/icons/CallRejectIcon";
import OutgoingPhoneIcon from "@/elements/v2/icons/OutgoingPhoneIcon";
import IncomingPhoneIcon from "@/elements/v2/icons/IncomingPhoneIcon";
import CalendarIcon from "@/elements/v2/icons/CalendarIcon";
import ClockIcon from "@/elements/v2/icons/ClockIcon";
import PlusIcon from "@/elements/v2/icons/PlusIcon";
import PauseIcon from "@/elements/v2/icons/PauseIcon";
import FlagIcon from "@/elements/v2/icons/FlagIcon";
import PlayIcon from "@/elements/v2/icons/PlayIcon";
import DownloadIcon from "@/elements/v2/icons/DownloadIcon";
import StopIcon from "@/elements/v2/icons/StopIcon";
import EnvelopeInIcon from "@/elements/v2/icons/EnvelopeIn";
import EnvelopeOutIcon from "@/elements/v2/icons/EnvelopeOut";
import EnvelopeFilled from "@/elements/v2/icons/EnvelopeFilled";
import PaperclipIcon from "@/elements/v2/icons/PaperclipIcon";
import TrashIcon from "@/elements/v2/icons/TrashIcon";
import AtIcon from "@/elements/v2/icons/AtIcon";
import SortIcon from "@/elements/v2/icons/SortIcon";
import DocumentsIcon from "@/elements/v2/icons/DocumentsIcon.vue";
import CallNeutral from "@/elements/v2/icons/CallNeutral.vue";
import SupportIcon from "@/elements/v2/icons/SupportIcon.vue";
import PhoneFilledIcon from "@/elements/v2/icons/PhoneFilledIcon.vue";
import PeselIcon from "@/elements/v2/icons/PeselIcon.vue";
import ShowPacientIcon from "@/elements/v2/icons/ShowPacientIcon.vue";
import ArrowLeft from "@/elements/v2/icons/ArrowLeft.vue";
import CashIcon from "@/elements/v2/icons/CashIcon.vue";
import EmailAt from "@/elements/v2/icons/EmailAt.vue";
import CloseMark from "@/elements/v2/icons/CloseMark.vue";
import NewCheckmarkIcon from "@/elements/v2/icons/NewCheckmarkIcon.vue";
import {markRaw} from "vue";

export default {
    name: "CustomIcon",
    props: {
        icon: {
            type: String,
            require: true,
        },
        w: {
            type: Number,
            require: false,
            default: 16,
        },
        h: {
            type: Number,
            require: false,
            default: 16,
        },
    },
    data: () => ({
        icons: markRaw({
            logo: LogoIcon,
            documents: DocumentsIcon,
            chevron: ChevronIcon,
            person: PersonIcon,
            personFilled: PersonFilledIcon,
            bell: BellIcon,
            chart: ChartIcon,
            settings: SettingsIcon,
            home: HomeIcon,
            envelope: EnvelopeIcon,
            menu: MenuIcon,
            phoneEmpty: PhoneIcon,
            callsList: CallsListIcon,
            search: SearchIcon,
            pencil: PencilIcon,
            checkmark: CheckmarkIcon,
            close: CloseIcon,
            closeMark: CloseMark,
            unimedWatermark: UnimedWatermarkIcon,
            callAnswer: CallAnswerIcon,
            callReject: CallRejectIcon,
            outgoingPhone: OutgoingPhoneIcon,
            incomingPhone: IncomingPhoneIcon,
            calendar: CalendarIcon,
            clock: ClockIcon,
            plus: PlusIcon,
            flag: FlagIcon,
            play: PlayIcon,
            download: DownloadIcon,
            pause: PauseIcon,
            stop: StopIcon,
            envelopeIn: EnvelopeInIcon,
            envelopeOut: EnvelopeOutIcon,
            envelopeFilled: EnvelopeFilled,
            paperclip: PaperclipIcon,
            trash: TrashIcon,
            at: AtIcon,
            sort: SortIcon,
            neutral: CallNeutral,
            support: SupportIcon,
            phoneFilled: PhoneFilledIcon,
            pesel: PeselIcon,
            showMore: ShowPacientIcon,
            arrowLeft: ArrowLeft,
            cashIcon: CashIcon,
            emailAt: EmailAt,
            checkmarkNew: NewCheckmarkIcon
        }),
    }),
};
</script>

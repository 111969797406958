import { notify } from "@kyvg/vue3-notification";
export const handleErrors = (e, msg) => {
    // Handle axios cancel
    // TODO: integrate axios.isCancel(error)
    if(e.code == 'ERR_CANCELED'){
        return;
    }

    console.log("Error: ", e?.response?.data?.message, e);

    let message = "";
    if (msg) {
        message = msg;
    } else if (e.response?.data?.message) {
        message = e.response.data.message;
    } else if (e.data?.messages) {
        const [[msg]] = Object.values(e.data.messages);
        message = msg;
    } else if (e.message) {
        message = e.message;
    } else {
        throw Error(e);
    }

    notify({
        group: "general",
        type: "error",
        text: message,
    });
};

export const handleSuccess = (val) => {
    notify({
        group: "general",
        type: "success",
        text: val,
    });
};

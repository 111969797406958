<template>
    <svg
        id="Group_23"
        data-name="Group 23"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="16.839"
        height="14.858"
        viewBox="0 0 16.839 14.858"
    >
        <path
            id="Path_19"
            data-name="Path 19"
            d="M8.3.005c1.828,0,3.657-.016,5.485.005a2.932,2.932,0,0,1,2.77,1.755,2.757,2.757,0,0,1,.274,1.225c0,2.021.017,6.878-.005,8.9a2.952,2.952,0,0,1-2.361,2.888,3.486,3.486,0,0,1-.7.076q-5.377.007-10.755,0A3.018,3.018,0,0,1,0,11.833C0,9.839,0,5.011,0,3.017A3.018,3.018,0,0,1,2.255.1,3.353,3.353,0,0,1,3.11.006H8.3m.141,1.2H3.1a3.087,3.087,0,0,0-.327.018A1.8,1.8,0,0,0,1.2,2.977c0,2.021,0,6.878,0,8.9a1.705,1.705,0,0,0,.49,1.212,1.839,1.839,0,0,0,1.392.568q5.372,0,10.744,0a1.8,1.8,0,0,0,1.8-1.8c0-2.021,0-6.878,0-8.9,0-.047,0-.094-.006-.141A1.826,1.826,0,0,0,13.818,1.2c-1.791.013-3.581,0-5.372,0"
            transform="translate(0 0)"
            fill="currentColor"
        />
        <path
            id="Path_20"
            data-name="Path 20"
            d="M41.786,47.307c.06.057.117.109.172.164q1.046,1.046,2.09,2.092c.1.1.194.2.284.3a.581.581,0,0,1,.12.612.566.566,0,0,1-.5.379.6.6,0,0,1-.513-.2q-1.088-1.09-2.178-2.177c-.106-.106-.209-.214-.337-.346-.1.114-.2.221-.294.321-.264.267-.526.536-.8.8a1.84,1.84,0,0,1-1.485.544,1.726,1.726,0,0,1-1.091-.511c-.347-.343-.692-.689-1.037-1.035a1.107,1.107,0,0,1-.081-.1c-.041.037-.066.059-.09.082l-2.172,2.169c-.1.1-.2.212-.314.309a.6.6,0,0,1-.983-.3.653.653,0,0,1,.206-.621q.531-.531,1.061-1.063l1.38-1.381.039-.041c-.024-.026-.046-.051-.069-.074l-2.058-2.059a.621.621,0,0,1-.093-.8.6.6,0,0,1,.854-.133,2.311,2.311,0,0,1,.219.205l3.638,3.638c.1.1.2.209.3.312a.611.611,0,0,0,.929,0l4.081-4.084a.6.6,0,0,1,.661-.16.583.583,0,0,1,.257.919,6.031,6.031,0,0,1-.465.49q-.834.838-1.672,1.674c-.019.019-.035.042-.06.072"
            transform="translate(-30.11 -40.788)"
            fill="currentColor"
        />
    </svg>
</template>

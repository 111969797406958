<template>
    <svg
        id="Group_190"
        data-name="Group 190"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="17.714"
        height="17.839"
        viewBox="0 0 17.714 17.839"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_90"
                    data-name="Rectangle 90"
                    width="17.714"
                    height="17.839"
                    fill="fill-current"
                />
            </clipPath>
        </defs>
        <g id="Group_189" data-name="Group 189" clip-path="url(#clip-path)">
            <path
                id="Path_73"
                data-name="Path 73"
                d="M0,26.41q0-2.482,0-4.965A2.89,2.89,0,0,1,.7,19.5a2.522,2.522,0,0,1,1.531-.832,5.8,5.8,0,0,1,.986-.086c1.274-.01,2.548,0,3.822,0a1.375,1.375,0,0,1,.318.038.49.49,0,0,1,.415.507.483.483,0,0,1-.4.528,1.27,1.27,0,0,1-.317.041c-1.409,0-2.818-.005-4.227.005a1.713,1.713,0,0,0-1.494.789,1.866,1.866,0,0,0-.272,1.072q0,3.868,0,7.735c0,.731-.013,1.463,0,2.194a1.649,1.649,0,0,0,.959,1.532,1.814,1.814,0,0,0,.825.183q4.983,0,9.967,0a1.676,1.676,0,0,0,1.643-1.161,2.89,2.89,0,0,0,.1-.774c.012-1.317.009-2.635.012-3.952a3.641,3.641,0,0,1,.012-.421.528.528,0,0,1,.538-.432.491.491,0,0,1,.491.465c.012.224.008.45.008.675,0,1.289.007,2.579,0,3.868a2.838,2.838,0,0,1-1.044,2.255,2.336,2.336,0,0,1-1.285.537c-.3.029-.595.057-.892.058q-4.7.006-9.407,0a2.978,2.978,0,0,1-2.045-.7,2.627,2.627,0,0,1-.89-1.687A8.017,8.017,0,0,1,0,30.953c0-1.514,0-3.029,0-4.543H0"
                transform="translate(0 -16.49)"
                fill="fill-current"
            />
            <path
                id="Path_74"
                data-name="Path 74"
                d="M53.168,2.381a2.286,2.286,0,0,1-.6,1.758c-.785.821-1.583,1.629-2.381,2.438Q47.753,9.043,45.314,11.5c-.184.186-.372.368-.556.554a2.686,2.686,0,0,1-1.422.632q-1.329.3-2.655.607a.562.562,0,0,1-.728-.615,8.385,8.385,0,0,1,.173-.88q.1-.466.214-.929c.138-.567.266-1.137.423-1.7a1.941,1.941,0,0,1,.538-.841q2.3-2.3,4.6-4.6c1.019-1.018,2.034-2.039,3.062-3.047A2.148,2.148,0,0,1,51.347.154a2.025,2.025,0,0,1,.578.359,6.749,6.749,0,0,1,.632.616,2.152,2.152,0,0,1,.611,1.252m-12,9.673c.074-.009.119-.011.163-.021l1.826-.411a1.812,1.812,0,0,0,.917-.448c.352-.349.706-.7,1.055-1.046,1.046-1.05,2.087-2.105,3.135-3.153.591-.59,1.193-1.17,1.835-1.8a1.869,1.869,0,0,1-.216-.156c-.576-.574-1.149-1.152-1.725-1.727-.14-.14-.145-.137-.287.007q-1,1.006-1.994,2.012-1.895,1.9-3.795,3.8a1.229,1.229,0,0,0-.355.605c-.122.519-.26,1.034-.387,1.552-.061.249-.111.5-.172.781M52.082,2.681a1.116,1.116,0,0,0-.339-.871q-.183-.193-.376-.376a1.173,1.173,0,0,0-1.652,0c-.246.235-.484.48-.722.725-.19.2-.191.2,0,.391.564.572,1.132,1.14,1.695,1.714.1.1.166.107.266.008.255-.254.52-.5.775-.751a1.054,1.054,0,0,0,.359-.84"
                transform="translate(-35.454 0)"
                fill="fill-current"
            />
        </g>
    </svg>
</template>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        data-name="Group 11"
        width="16"
        height="17"
        viewBox="0 0 16.076 17"
        fill="#131338"
    >
        <path
            data-name="Path 10"
            d="M8.02 14.255H2.469a2.358 2.358 0 0 1-1.523-.471 2.457 2.457 0 0 1-.936-2.243 2.168 2.168 0 0 1 .526-1.227 2.8 2.8 0 0 0 .656-1.371c.06-.4.09-.8.14-1.2.1-.829.172-1.666.33-2.485a6.421 6.421 0 0 1 2.1-3.615A6.239 6.239 0 0 1 7.023.081a6.3 6.3 0 0 1 2.164.026 6.387 6.387 0 0 1 3.034 1.447 7 7 0 0 1 1.4 1.682 6.7 6.7 0 0 1 .9 2.681c.118.936.221 1.874.325 2.812a2.765 2.765 0 0 0 .589 1.456 2.656 2.656 0 0 1 .585 1.113A2.471 2.471 0 0 1 14.781 14a2.221 2.221 0 0 1-.973.247c-1.139.006-2.278.005-3.417.006H8.02Zm.005-1.382v.007h5.648a1.18 1.18 0 0 0 .257-.024 1.083 1.083 0 0 0 .782-1.111 1.237 1.237 0 0 0-.315-.693 4.119 4.119 0 0 1-.9-2.133c-.115-.941-.209-1.883-.327-2.823a5.225 5.225 0 0 0-1.6-3.275 4.928 4.928 0 0 0-4.69-1.3 4.933 4.933 0 0 0-3.224 2.373 5.09 5.09 0 0 0-.727 2C2.8 6.91 2.7 7.932 2.577 8.95a3.517 3.517 0 0 1-.435 1.387 9.369 9.369 0 0 1-.536.8 1.062 1.062 0 0 0-.209.94 1.006 1.006 0 0 0 .992.8c.14-.006.28 0 .42 0h5.216"
        ></path>
        <path
            data-name="Path 11"
            d="M8.036 17.936a3.657 3.657 0 0 1-3.375-2.239.679.679 0 1 1 1.238-.558 2.428 2.428 0 0 0 .488.742 2.372 2.372 0 0 0 3.377-.075 2 2 0 0 0 .39-.626.681.681 0 0 1 1.31.365 2.622 2.622 0 0 1-.549 1.026 3.7 3.7 0 0 1-2.878 1.365"
        ></path>
    </svg>
</template>

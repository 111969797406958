<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
    >
        <g
            id="Group_8232"
            data-name="Group 8232"
            transform="translate(-1632 -468)"
        >
            <g
                id="Ellipse_24"
                data-name="Ellipse 24"
                transform="translate(1632 468)"
                fill="none"
                stroke="#34c3ea"
                stroke-width="2"
            >
                <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
                <circle cx="9.5" cy="9.5" r="8.5" fill="none" />
            </g>
            <line
                id="Line_57"
                data-name="Line 57"
                y2="7"
                transform="translate(1641.5 474)"
                fill="none"
                stroke="#34c3ea"
                stroke-linecap="round"
                stroke-width="2"
            />
            <line
                id="Line_58"
                data-name="Line 58"
                y2="7"
                transform="translate(1645 477.5) rotate(90)"
                fill="none"
                stroke="#34c3ea"
                stroke-linecap="round"
                stroke-width="2"
            />
        </g>
    </svg>
</template>

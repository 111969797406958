<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.68"
        height="21.348"
        viewBox="0 0 19.68 21.348"
    >
        <path
            data-name="Path 85"
            d="M15.843.859A9.354 9.354 0 0 0 11.75 0a11.416 11.416 0 0 0-6.416 1.767 11.163 11.163 0 0 0-4 4.606 14.081 14.081 0 0 0-1.33 6.04 9.262 9.262 0 0 0 1.119 4.532 8.09 8.09 0 0 0 3.312 3.225 10.981 10.981 0 0 0 5.263 1.178 16.086 16.086 0 0 0 3.874-.483 12.285 12.285 0 0 0 3.441-1.4 1.678 1.678 0 0 0 .632-.626 1.609 1.609 0 0 0 .2-.783 1.483 1.483 0 0 0-.344-.98 1.242 1.242 0 0 0-1.6-.256 15.327 15.327 0 0 1-3.114 1.248 11.653 11.653 0 0 1-3.014.4 6.457 6.457 0 0 1-4.861-1.67 6.257 6.257 0 0 1-1.6-4.491 11.71 11.71 0 0 1 .936-4.694 7.972 7.972 0 0 1 2.773-3.419 7.673 7.673 0 0 1 4.482-1.284 5.715 5.715 0 0 1 3.821 1.181 4.21 4.21 0 0 1 1.354 3.412 8.172 8.172 0 0 1-.452 2.765 5.628 5.628 0 0 1-1.1 1.949 1.641 1.641 0 0 1-1.109.642.711.711 0 0 1-.475-.118.415.415 0 0 1-.118-.313 19.423 19.423 0 0 1 .461-3.142 14.988 14.988 0 0 0 .308-2.076c0-.318-.13-1.056-1.337-1.056a5.262 5.262 0 0 0-.629.037 1.317 1.317 0 0 0-.242-.451 1.087 1.087 0 0 0-.857-.29 4.829 4.829 0 0 0-2.826.939 6.721 6.721 0 0 0-2.09 2.437 6.91 6.91 0 0 0-.783 3.208 3.928 3.928 0 0 0 .837 2.609 2.945 2.945 0 0 0 2.37 1.02 2.893 2.893 0 0 0 1.678-.515 3.349 3.349 0 0 0 .8-.784 2.922 2.922 0 0 0 2.689 1.3 4.753 4.753 0 0 0 2.874-1.025 7.139 7.139 0 0 0 2.18-2.862 10.265 10.265 0 0 0 .825-4.225 7.924 7.924 0 0 0-1.014-4.11A6.543 6.543 0 0 0 15.845.863M10.32 11.296a3.059 3.059 0 0 1-.517 1.244.792.792 0 0 1-.565.385.5.5 0 0 1-.423-.175 1.127 1.127 0 0 1-.17-.7 5.127 5.127 0 0 1 .395-1.995 3.934 3.934 0 0 1 1.041-1.487 2.211 2.211 0 0 1 .885-.485Z"
            fill="#fff"
        />
    </svg>
</template>

<template>
    <div
        class="formulate-select custom-tree-select"
        :class="active ? 'z-30' : ''"
        v-click-outside="() => active && clickOutside()"
    >
        <div v-if="disabled" class="absolute top-0 left-0 w-full h-full rounded-[10px] z-50 border border-[#DCDCDC]" :class="{'!bg-zinc-500/25': disabled}">
        </div>
        <button
            class="flex justify-between items-center cursor-pointer w-full"
            @click.stop="toggle"
            type="button"
            :disabled="disabled"
            @click="clickInSide"
        >
            <div
                :class="[valueLabel && hasValue ? 'text-blue-500' : 'text-gray-500', {'text-gray-500': disabled}]"
                class="font-light flex items-center h-10 sm:h-12 text-left text-blue-500"
            >
                {{ valueLabel || placeholder }}
            </div>

            <div
                :class="{
                    'transform transition text-green-400': true,
                    'rotate-180': !active,
                }"
            >
                <custom-icon icon="chevron" :w="12" :h="12" />
            </div>
        </button>

        <div>
            <div class="input-tree-select">
                <div
                    v-for="(category, i) in Object.keys(options)"
                    :key="category"
                >
                    <p
                        class="text-blue-600 font-bold py-2 mt-2 px-3"
                        :class="{ hidden: !active }"
                    >
                        {{ category === "uncategorized" ? "Główne" : category }}
                    </p>
                    <FormKit
                        :options="options[category]"
                        type="radio"
                        :name="name"
                        v-model="value"
                        error-behavior="submit"
                        :validation="validation"
                        :validation-name="validationName"
                        :validation-rules="validationRules"
                        :validation-messages="validationMessages"
                        :outer-class="{ 'is-hidden': !active, 'z-20': active }"
                        :disabled="disabled"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from "click-outside-vue3"
import CustomIcon from "@/elements/v2/CustomIcon";
import {mapGetters} from "vuex";

export default {
    name: "CustomTreeSelect",
    components: {
        CustomIcon,
    },
    props: {
        options: {
            type: Object,
            require: true,
        },
        name: String,
        validation: String,
        validationName: String,
        validationRules: [Object, String],
        validationMessages: Object,
        errors: Object,
        placeholder: String,
        defaultValue: [String, Number],
        disabled: Boolean,
        hide: Boolean
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data: () => ({
        value: null,
        active: false,
    }),
    computed: {
        ...mapGetters('dicts', ['select']),
        selection(){
            return !this.metafieldId ? this.select === this.name : this.select === `${this.name}-${this.metafieldId}`;
        },
        valueLabel() {
            if (this.hasValue) {
                const options = Object.values(this.options).flat();
                if (options?.length) {
                    const option = options.find((opt) => opt.id == this.value);
                    return option?.label || this.value;
                }
                return this.value;
            } else {
                return this.placeholder;
            }

        },
        hasValue() {
            return this.value || this.value === 0;
        },
    },
    updated() {
        if (!this.value) {
            this.value = this.defaultValue;
        }
    },
    mounted() {
        if (
            !this.value &&
            (this.defaultValue?.length || this.defaultValue === 0)
        ) {
            this.value = this.defaultValue;
        }
        this.initialRender = false;
    },
    methods: {
        toggle() {
            this.active = !this.active;
        },
        clickOutside() {
            this.active = false;
        },
        clickInSide() {
            const selectId = !this.metafieldId ? this.name : `${this.name}-${this.metafieldId}`;
            this.$store.dispatch('dicts/updateSelect', selectId)
        },
    },
    watch: {
        selection: {
            handler(val) {
                if (!val){
                    this.active = false;
                }
            },
            deep: false
        },
        value(value) {
            if (this.initialRender || !this.value) return;
            this.active = false
            if (value) {
                this.$emit("update-form", { key: this.name, value });
            }
        },

        defaultValue(newVal) {
            if (this.value !== newVal) {
                this.value ??= newVal;
            }
        },
    },
};
</script>

<template>
    <svg id="Group_132" data-name="Group 132" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="5.45" height="23.33" viewBox="0 0 5.45 23.33">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_109" data-name="Rectangle 109" width="5.45" height="23.33" fill="#b9c9e0"/>
            </clipPath>
        </defs>
        <g id="Group_131" data-name="Group 131" clip-path="url(#clip-path)">
            <path id="Path_6071" data-name="Path 6071" d="M5.448,8.3q0,3.014,0,6.028a2.467,2.467,0,0,1-.285,1.228,1.874,1.874,0,0,1-1.377.97,5.479,5.479,0,0,1-2.195-.02A1.945,1.945,0,0,1,.023,14.678C0,14.352,0,14.023,0,13.7Q0,8.082,0,2.469A3.134,3.134,0,0,1,.154,1.331,1.845,1.845,0,0,1,1.56.134a5.2,5.2,0,0,1,2.328,0A1.944,1.944,0,0,1,5.441,2.061c.013,2.078,0,4.157,0,6.235h0M1.3,8.329h0q0,2.851,0,5.7c0,.173-.008.346,0,.518a.627.627,0,0,0,.579.645,15.684,15.684,0,0,0,1.638.019.579.579,0,0,0,.579-.525,6.693,6.693,0,0,0,.047-.928q0-5.261,0-10.521c0-.363.013-.726,0-1.088A.707.707,0,0,0,3.411,1.4c-.461-.013-.922-.009-1.382,0a.636.636,0,0,0-.661.457,2.271,2.271,0,0,0-.064.613Q1.3,5.4,1.3,8.329" transform="translate(0 0)" fill="currentColor"/>
            <path id="Path_6072" data-name="Path 6072" d="M5.45,184.253a2.466,2.466,0,0,1-.285,1.228,1.874,1.874,0,0,1-1.377.97,5.479,5.479,0,0,1-2.195-.02,1.945,1.945,0,0,1-1.571-1.824C0,184.281,0,183.952,0,183.624c0-3.742,0,2.869,0-.873a3.132,3.132,0,0,1,.152-1.138,1.845,1.845,0,0,1,1.407-1.2,5.2,5.2,0,0,1,2.328,0,1.944,1.944,0,0,1,1.552,1.929c.013,2.078.005-.1.01,1.909M1.3,183.959c0,.173-.008.346,0,.518a.627.627,0,0,0,.579.645,15.684,15.684,0,0,0,1.638.019.579.579,0,0,0,.579-.525,6.723,6.723,0,0,0,.047-.928c0-3.507,0,3.34,0-.167,0-.363.013-.726,0-1.088a.707.707,0,0,0-.737-.749c-.461-.013-.922-.009-1.382,0a.636.636,0,0,0-.661.457,2.267,2.267,0,0,0-.064.613c0,1.952,0-.7,0,1.2" transform="translate(0 -163.222)" fill="currentColor"/>
        </g>
    </svg>
</template>
<script setup>
</script>

<template>
    <svg
        data-name="Group 9"
        xmlns="http://www.w3.org/2000/svg"
        width="17.563"
        height="17.562"
        viewBox="0 0 17.563 17.562"
    >
        <path
            data-name="Path 9"
            d="M12.376 17.56a13.029 13.029 0 0 1-2.436-.3 13.116 13.116 0 0 1-3.551-1.36 12.6 12.6 0 0 1-3.052-2.428 11.935 11.935 0 0 1-1.2-1.518 12.535 12.535 0 0 1-1.83-4.286A12.645 12.645 0 0 1 0 4.933a4.8 4.8 0 0 1 .633-2.414 4.937 4.937 0 0 1 2.84-2.3A4.547 4.547 0 0 1 4.856 0a.545.545 0 0 1 .571.388Q6.2 2.318 6.97 4.249l.6 1.519a.591.591 0 0 1 .052.358.508.508 0 0 1-.322.374c-.333.135-.667.267-1 .4l-1.27.51c-.135.054-.137.055-.126.2a5.029 5.029 0 0 0 .376 1.584 5.474 5.474 0 0 0 .79 1.367A5.553 5.553 0 0 0 9.489 12.6c.177.031.357.042.536.063a.079.079 0 0 0 .1-.058q.4-1.018.812-2.034c.043-.109.083-.219.131-.325a.5.5 0 0 1 .672-.283c.42.162.837.335 1.255.5l2.875 1.151c.432.172.865.341 1.3.513a.556.556 0 0 1 .4.607 4.915 4.915 0 0 1-4.044 4.74 5.3 5.3 0 0 1-1.143.085m-.56-6.463-.087.222q-.4 1-.8 2.009a.531.531 0 0 1-.562.373 6.24 6.24 0 0 1-1.549-.2 6.78 6.78 0 0 1-2.276-1.081 6.623 6.623 0 0 1-2.678-5.2.536.536 0 0 1 .387-.578c.258-.107.517-.211.777-.315.447-.18.893-.362 1.342-.537.082-.032.087-.061.057-.135q-.9-2.244-1.788-4.49c-.03-.075-.05-.123-.151-.111a4 4 0 0 0-1.576.54 3.8 3.8 0 0 0-1.226 1.155 3.722 3.722 0 0 0-.663 1.988A11.171 11.171 0 0 0 1.191 6.9a10.734 10.734 0 0 0 .657 2.325A11.486 11.486 0 0 0 4.4 13.106a11.555 11.555 0 0 0 5.177 3.018c.435.107.872.213 1.314.278.46.067.926.089 1.391.122a4.229 4.229 0 0 0 1.076-.056 3.871 3.871 0 0 0 2.371-1.454 3.945 3.945 0 0 0 .788-1.945c.013-.089-.03-.112-.1-.138l-4.4-1.761c-.065-.026-.133-.048-.205-.073"
            fill="currentColor"
            stroke="currentColor"
            stroke-width=".3"
        />
    </svg>
</template>

import axiosClient from "../apiClient";
import slots from "@/store/fakeData/doctor-object.json";
import dayjs, {Dayjs} from "dayjs";


export function createVisit(payload) {
    return axiosClient.post("/clinic/visits", payload.data);
}

export function getVisits(page, per_page, filters = [], sort = null) {
    const params = new URLSearchParams({
        page,
        per_page,
    });

    if (filters.length) {
        filters.forEach((filter) => {
            params.append(`filter[${filter.key}]`, filter.value);
        });
    }

    if(sort) {
        params.append('sort', `${sort.order === 'asc' ? '' : '-'}${sort.name}`)
    }

    return axiosClient.get(
        `/clinic/visits?${params.toString()}`
    );
}

export function getVisitsByFilter(patientID = null, doctorID = null, statusID=null) {
    const params = new URLSearchParams();
    if (patientID) {
        params.append(`filter[patient_user_id]`, patientID);
    }
    if (doctorID) {
        params.append(`filter[doctor_user_id]`, doctorID);
    }
    if (statusID) {
        params.append(`filter[visit_status_id]`, doctorID);
    }
    return axiosClient.get(
        `/clinic/visits?${params.toString()}`
    );
}

export function editVisit({ id, data }) {
    return axiosClient.patch(`/clinic/visits/${id}`, data);
}

export function searchVisits(needle) {
    return axiosClient.get(`/clinic/visits?search=${needle}&per_page=99999`);
}

export function getVisit(visitID) {
    return axiosClient.get(`/clinic/visits/${visitID}`);
}

export function getDoctorSlots(startDate, departmentID, visitTypeID, userID) {
    const params = new URLSearchParams();
    if (startDate){
        params.append('start_date', dayjs(startDate).day(1).format('YYYY-MM-DD'))
    } else {
        params.append('start_date', dayjs().day(1).format('YYYY-MM-DD'))
    }
    if (departmentID) {
        params.append(`filter[department_id]`, departmentID);
    }
    if (visitTypeID) {
        params.append(`filter[visit_type_id]`, visitTypeID);
    }
    if (userID) {
        params.append(`filter[user_id]`, userID);
    }
    return axiosClient.get(`/clinic/doctor-calendars?${params.toString()}`);
}

import dayjs from "dayjs";

export const pagination = {
    INCREMENT: "increment",
    DECREMENT: "decrement",
};

export const callStatus = {
    PENDING: "pending",
    COMPLETED: "completed",
    PROCESSING: "processing",
};

export const smsFilter = {
    ALL: -1,
    UNREAD: 0,
    READ: 1,
};

export const patientMessagesLimit = 6;

export const statuses = [
    {
        label: "Odebrane - przychodzące",
        status: "answered",
        type: "incoming",
    },
    {
        label: "Odebrane - wychodzące",
        status: "answered",
        type: "outgoing",
    },
    {
        label: "Zakończone - przychodzące",
        status: "ended",
        type: "incoming",
    },
    {
        label: "Zakończone - wychodzące",
        status: "ended",
        type: "outgoing",
    },
    {
        label: "Odrzucone - przychodzące",
        status: "rejected",
        type: "incoming",
    },
    {
        label: "Odrzucone - wychodzące",
        status: "rejected",
        type: "outgoing",
    },
    {
        label: "Nie odebrano - przychodzące",
        status: "not_answered",
        type: "incoming",
    },
    {
        label: "Nie odebrano - wychodzące",
        status: "not_answered",
        type: "outgoing",
    },
    {
        label: "Do oddzwonienia",
        status: "not_answered",
        type: "incoming",
        called_back: "0",
        icon: "flag",
    },
];

const currentDate = dayjs();

export const dateFilter = {
    today: {
        label: "Dziś",
        start: currentDate,
        end: currentDate,
        type: "date",
        value: "today",
    },
    lastWeek: {
        label: "Ostatni tydzień",
        start: currentDate.subtract(8, "day"),
        end: currentDate.subtract(1, "day"),
        type: "date",
        value: "lastWeek",
    },
    currentWeek: {
        label: "Bieżący tydzień",
        start: currentDate.startOf("week").add(1, "day").toDate(),
        end: currentDate.endOf("week").add(1, "day").toDate(),
        type: "date",
        value: "currentWeek",
    },
    nextWeek: {
        label: "Przyszły tydzień",
        start: currentDate
            .startOf("week")
            .add(1, "day")
            .add(1, "week")
            .toDate(),
        end: currentDate.endOf("week").add(1, "week").add(1, "day").toDate(),
        type: "date",
        value: "nextWeek",
    },
    tillNow: {
        label: "Do teraz",
        start: dayjs("1970-01-01").toDate(),
        end: currentDate,
        type: "date",
        value: "tillNow",
    },
    lastMonth: {
        label: "Ostatni miesiąc",
        start: currentDate.subtract(30, "day"),
        end: currentDate,
        type: "date",
        value: "lastMonth",
    },
    lastYear: {
        label: "Ostatni rok",
        start: currentDate.subtract(365, "day"),
        end: currentDate.add(1, "day"),
        type: "date",
        value: "lastYear",
    },
};

export const extendedDates = [
    {
        label: "Dziś",
        start: currentDate,
        end: currentDate,
        default: false,
        value: "today",
    },
    {
        label: "Ostatnie 7 dni",
        start: currentDate.subtract(8, "day"),
        end: currentDate.subtract(1, "day"),
        default: false,
        value: "lastWeek",
    },
    {
        label: "Ostatni miesiąc",
        start: currentDate.subtract(30, "day"),
        end: currentDate,
        default: true,
        value: "lastMonth",
    },
];

export const trendsDates = [
    {
        type: "period",
        label: "Bieżący rok",
        value: "current_year",
        default: true,
    },
    {
        type: "period",
        label: "Bieżący kwartał",
        value: "current_quarter",
        default: false,
    },
    {
        type: "period",
        label: "Bieżący miesiąc",
        value: "current_month",
        default: false,
    },
];

export const trendsDatesPrevious = [
    {
        type: "period",
        label: "Rok do roku",
        value: "previous_year",
        default: false,
    },
    {
        type: "period",
        label: "Poprzedni okres",
        value: "previous_time_slot",
        default: false,
    },
];

export const filterHours = [
    {
        label: "w ciągu 1 godziny",
        hours: 1,
        default: true,
    },
    {
        label: "w ciągu 2 godzin",
        hours: 2,
        default: false,
    },
    {
        label: "w ciągu 3 godzin",
        hours: 3,
        default: false,
    },
    {
        label: "w ciągu 4 godzin",
        hours: 4,
        default: false,
    },
    {
        label: "w ciągu 5 godzin",
        hours: 5,
        default: false,
    },
    {
        label: "w ciągu 6 godzin",
        hours: 6,
        default: false,
    },
    {
        label: "w ciągu 7 godzin",
        hours: 7,
        default: false,
    },
    {
        label: "w ciągu 8 godzin",
        hours: 8,
        default: false,
    },
    {
        label: "w ciągu 9 godzin",
        hours: 9,
        default: false,
    },
    {
        label: "w ciągu 10 godzin",
        hours: 10,
        default: false,
    },
    {
        label: "w ciągu 11 godzin",
        hours: 11,
        default: false,
    },
    {
        label: "w ciągu 12 godzin",
        hours: 12,
        default: false,
    },
    {
        label: "w ciągu 13 godzin",
        hours: 13,
        default: false,
    },
    {
        label: "w ciągu 14 godzin",
        hours: 14,
        default: false,
    },
    {
        label: "w ciągu 15 godzin",
        hours: 15,
        default: false,
    },
    {
        label: "w ciągu 16 godzin",
        hours: 16,
        default: false,
    },
    {
        label: "w ciągu 17 godzin",
        hours: 17,
        default: false,
    },
    {
        label: "w ciągu 18 godzin",
        hours: 18,
        default: false,
    },
    {
        label: "w ciągu 19 godzin",
        hours: 19,
        default: false,
    },
    {
        label: "w ciągu 20 godzin",
        hours: 20,
        default: false,
    },
    {
        label: "w ciągu 21 godzin",
        hours: 21,
        default: false,
    },
    {
        label: "w ciągu 22 godzin",
        hours: 22,
        default: false,
    },
    {
        label: "w ciągu 23 godzin",
        hours: 23,
        default: false,
    },
    {
        label: "w ciągu 24 godzin",
        hours: 24,
        default: false,
    },
];

export const searchListings = [
    {
        label: "Pacjenci",
        value: "patients",
        route: "Patients",
        action: "searchPatients",
    },
    {
        label: "Połączenia",
        value: "calls",
        route: "Calls",
        action: "searchCalls",
    },
];

export const contactHistoryDashboard = [
    {
        title: "SMS",
        details: [
            {
                id: 0,
                icon: "envelopeIn",
                class: "bg-green-300",
                label: "Odebrane",
                key: "sms",
                value: "in",
            },
            {
                id: 1,
                icon: "envelopeOut",
                class: "bg-blue-400",
                label: "Wysłane",
                key: "sms",
                value: "out",
            },
        ],
        component: "SmsesPopup",
    },
    {
        title: "e-mail",
        details: [
            {
                id: 1,
                icon: "at",
                class: "bg-blue-400",
                label: "Wysłane",
                key: "email",
                value: "out",
            },
        ],
        component: "EmailsPopup",
    },
    {
        title: "Połączenia",
        details: [
            {
                id: 0,
                icon: "incomingPhone",
                class: "bg-green-300  text-white",
                label: "Przychodzące",
                key: "call",
                value: "in",
            },
            {
                id: 1,
                icon: "outgoingPhone",
                class: "bg-blue-400 text-white",
                label: "Wychodzące",
                key: "call",
                value: "out",
            },
        ],
        component: "CallsPopup",
    },
];

export const sources = {
    mail: "E-mail",
    phone: "Telefon",
    directly: "Rejestracja bezpośrednia",
    www: "Strona internetowa",
};
